$purple-default: #8d4afc;
$purple-light: #dac2f2;
$purple-extralight: #f9f3ff;
$purple-hover: #9851fc;
$purple-active: #8142fb;
$purple-medium: #986be1;
$purple-medium2: #8c69dc;
$purple-lavender: #e9e1f9;
$violet: #ad63da;
$violet-name: #8d3eff;
$deep-lilac: #9b59b6;
$purple-dark: #ad63da;
$violet-light: #755fff;
$purple-pale-lavender: #e2d3fa;
$purple-pale: #c6a4fd;
$purple-lavender-darker: #f1e9ff;
$purple-magnolia: #f6f1ff;
$violet-lavender: #ede2fe;

$yellow-default: #ffbb00;
$yellow-light: #fff3d1;
$yellow-extralight: #fff9e8;
$yellow-hover: #ffc500;
$yellow-active: #ffb000;
$yellow-bright: #ffa220;
$yellow-dark: #f3a52e;
$yellow-blanched-almond: #fff1cc;
$yellow-cosmic-latte: #fffaeb;
$yellow-cornsilk: #fff4d7;
$yellow-cornsilk-dark: #fff6e0;

$blue-default: #1e7ef7;
$blue-light: #cfe9ff;
$blue-extralight: #f1f8ff;
$blue-hover: #208af7;
$blue-active: #1a71f6;
$vivid-cerulean: #00aadd;
$light-blue: #4ea1ce;
$royal-blue: #3075ea;
$tufts-blue: #3498db;
$blue-navy: #1ab6d2;
$blue-jeans: #f6faff;
$blue-jeans: #f6faff;
$blue-denim: #57b7df;
$blue-light-steel: #bbd6ee;
$blue-alice: #e8f2fe;
$blue-alice-light: #edf5fe;

$green-default: #1eaa5c;
$green-light: #bae5cd;
$green-extralight: #f6faef;
$green-hover: #20b265;
$green-active: #1aa152;
$green-chrome: #24a863;
$june-bud: #add945;
$deepemerald: #2ba062;
$emerald: #51d473;
$green-primary: #1eaa5c;
$green-medium-dark: #81c479;
$green-dark: #2ba062;
$green-pastel: #3ac8b0;
$green-olivine: #c9e69a;
$green-crayola: #1eaa5c;
$green-mint-cream: #f6fcf9;
$green-chinese-white: #d2eede;
$green-honeydew: #edf9f2;

$orange-default: #ff871e;
$orange-extralight: #fff5ec;
$orange-hover: #ff9220;
$orange-active: #ff7b1b;
$halloween-orange: #f45d29;
$orange-peel: #ff9e04;
$orange-generic: #ff8d28;
$orange-seashell: #fff0eb;
$orange-red: #ff6e26;
$orange-dark: #f3743e;
$orange-ripe: #ffbd2e;
$orange-yellow: #ffa500;
$orange-seashell-dark: #fff3ee;
$orange-linen: #ffede5;
$orange-deep-saffron: #ff9b26;

$red-default: #ee4646;
$red-light: #ffe0e0;
$red-extralight: #fff3f3;
$red-hover: #f15454;
$red-active: #eb3b3b;
$red-delete: #f14135;
$red-fire-opal: #ef5050;
$red-bittersweet: #ff7356;
$red-coral: #fb4141;
$red-lavender-blush: #fef0f0;
$red-misty-rose: #fee4e4;
$red-pigment: #f22525;
$red-misty-rose-darker: #fce1e1;

$grey-extralight: #fafbfc;
$grey-light: #f4f6fa;
$grey-softlight: #ebeff3;
$grey-soft: #c1c9d4;
$grey-middle: #929ba9;
$grey-dark: #4d5054;
$grey-extradark: #232426;
$grey-extrlight: #676f7d;
$grey-light-2: #d4dae2;
$grey-light-3: #d2d2d3;
$grey-boder-line: #eef2f8;
$grey-greenish: #60708c;
$grey-greenish-light: #eaecf2;
$grey-tinsmith: #c4c4c4;
$grey-silver: #c0c0c0;
$light-silver: #d8d8d8;
$grey-silver-light: #cdcdcd;
$grey-very-light: #e4e6e9;
$grey-generic: #999999;
$grey-medium: #b2b7be;
$grey-verylight: #cccccc;
$grey-verylight-brown: #ebebeb;
$grey-platinum: #e5e5e5;
$grey-platinum-dark: #e4e4e4;
$granite-gray: #666666;
$date-color: #a0adbe;
$grey-quick-silver: #a4a5a7;
$grey-faux-granite: #5f6164;
$grey-very-dark: #4c4c4c;
$gray-light: #babbbc;
$grey-light-silver: #d9d9d9;
$grey-storm: #767676;
$grey-sonic-silver: #77787a;
$grey-storm: #767676;
$grey-outer-space: #494c4e;
$gray-default: #d0d0d0;
$gray-iron: #dadbdb;
$gray-brown: #bdbdbd;
$grey-philippine-silver: #b2b2b2;
$grey-american-silver: #cfcfcf;
$grey-bright: #ececec;

$black: #000000;
$black-2: #313438;
$black-3: #555b64;
$black-4: #354156;
$black-5: #202633;
$black-6: #8792a3;
$black-7: #c4ccd5;
$black-gunmetal: #2c2f34;
$black-eerie: #191919;
$black-light: rgba(0, 0, 0, 0.04);
$black-verydark: rgba(0, 0, 0, 0.06);
$black-extradark: rgba(0, 0, 0, 0.08);
$black-darker: rgba(0, 0, 0, 0.3);
$black-darker1: rgba(0, 0, 0, 0.9);
$black-darker2: rgba(0, 0, 0, 0.6);
$black-darker3: rgba(0, 0, 0, 0.7);
$black-darker4: rgba(0, 0, 0, 0.1);
$black-darker5: rgba(0, 0, 0, 0.25);
$black-darker6: rgba(0, 0, 0, 0.16);
$black-dark-jungle: rgba(28, 31, 34, 0.2);
$black-eerie-dark: #1a1a1a;
$black-jungle-green: #1c1f22;
$black-darklight: rgba(28, 31, 34, 0.6);
$black-verylight: rgba(28, 31, 34, 0.04);
$black-verylight-green: rgba(28, 31, 34, 0.8);
$black-dark-liver: #4d4d4d;
$black-dark-charcoal: #333333;

$bg-shawdown: #979797;

$white: #ffffff;
$white-grey: #fcfcfc;
$white-darkgrey: #f6f7f8;
$white-bright: #e9e9e9;
$white-cultured: #f5f5f5;
$light-brown: #eaeaea;
$white-dark: #fafafa;
$white-flash: #f0f0f0;
$white-lotion: #fafafa;
$white-anti-flash: #f2f2f2;
$white-light-cultured: #f6f6f6;
$white-ghost: #f9fafc;
$white-light: rgba(255, 255, 255, 0.7);
$white-ghost-light: #fbfcff;
$white-ghost-lighter: #fdfbff;

$boder-color-yellow: #feedbf;
$border-color-grey: rgba(0, 0, 0, 0.14);
$background-yellow-opacity: rgba(255, 187, 0, 0.15);
$background-title: #464b55;
$background-input: #f2f5f7;
$background-circle: #c4ccd9;
$background-grey: #edeeef;

$pink-default: #dc46ae;
$pink-dark: #e0507b;
$pink-linen: #fdecec;
$pink-vivid-tangerine: #fb9c86;
$pink-orchid: #e061e2;
$pink-light-salmon: #ffa1a1;
$pink-spanish: #f9bdbd;

$btn-hover-black: #7a83a6;
$btn-hover-gray: #8c929a;
$btn-disable: #f1f5f8;

$transparent: transparent;
$bg-body: #f9f9f9;

$btn-hover: #fff9e6;
$btn-press: #fff5d9;
$cosmic-latte: #fff8e5;
$very-light-gray: #d1d1d2;
$anti-flash-white: #f1f3f5;
$very-light-blue: #dee4eb;
$very-dark-white: #f7f8f9;
$cultured: #f4f5f6;
$bg-tabitem: rgba(0, 0, 0, 0.02);
$extra-white: #eeeeee;
$generic-gray: #6c7079;
$boder-color-message: #ffebb3;

$colors: (
    'grey-verylight': $grey-verylight,
    'grey-verylight-brown': $grey-verylight-brown,
    'black-verydark': $black-verydark,
    'orange-generic': $orange-generic,
    'red-coral': $red-coral,
    'white-bright': $white-bright,
    'white-cultured': $white-cultured,
    'white-darkgrey': $white-darkgrey,
    'white-grey': $white-grey,
    'background-grey': $background-grey,
    'date-color': $date-color,
    'red-delete': $red-delete,
    'violet-name': $violet-name,
    'btn-hover-black': $btn-hover-black,
    'background-title': $background-title,
    'royal-blue': $royal-blue,
    'background-yellow-opacity': $background-yellow-opacity,
    'black': $black,
    'black-2': $black-2,
    'black-3': $black-3,
    'black-4': $black-4,
    'black-5': $black-5,
    'black-6': $black-6,
    'black-7': $black-7,
    'bg-shawdown': $bg-shawdown,
    'boder-color-yellow': $boder-color-yellow,
    'purple-default': $purple-default,
    'purple-light': $purple-light,
    'purple-extralight': $purple-extralight,
    'purple-hover': $purple-hover,
    'purple-active': $purple-active,
    'yellow-default': $yellow-default,
    'yellow-light': $yellow-light,
    'yellow-extralight': $yellow-extralight,
    'yellow-hover': $yellow-hover,
    'yellow-active': $yellow-active,
    'blue-default': $blue-default,
    'blue-light': $blue-light,
    'blue-extralight': $blue-extralight,
    'blue-hover': $blue-hover,
    'blue-active': $blue-active,
    'green-default': $green-default,
    'green-light': $green-light,
    'green-extralight': $green-extralight,
    'green-hover': $green-hover,
    'green-active': $green-active,
    'green-primary': $green-primary,
    'violet': $violet,
    'orange-default': $orange-default,
    'orange-extralight': $orange-extralight,
    'orange-hover': $orange-hover,
    'orange-active': $orange-active,
    'orange-seashell': $orange-seashell,
    'orange-red': $orange-red,
    'red-default': $red-default,
    'red-light': $red-light,
    'red-extralight': $red-extralight,
    'red-hover': $red-hover,
    'red-active': $red-active,
    'grey-extrlight': $grey-extrlight,
    'grey-extralight': $grey-extralight,
    'grey-light': $grey-light,
    'grey-light-3': $grey-light-3,
    'grey-softlight': $grey-softlight,
    'grey-soft': $grey-soft,
    'grey-middle': $grey-middle,
    'grey-dark': $grey-dark,
    'grey-extradark': $grey-extradark,
    'white': $white,
    'pink-default': $pink-default,
    'transparent': $transparent,
    'border-color-grey': $border-color-grey,
    'body': $bg-body,
    'halloween-orange': $halloween-orange,
    'orange-peel': $orange-peel,
    'june-bud': $june-bud,
    'vivid-cerulean': $vivid-cerulean,
    'red-fire-opal': $red-fire-opal,
    'purple-medium': $purple-medium,
    'green-chrome': $green-chrome,
    'black-gunmetal': $black-gunmetal,
    'grey-silver': $grey-silver,
    'red-bittersweet': $red-bittersweet,
    'light-blue': $light-blue,
    'light-silver': $light-silver,
    'light-brown': $light-brown,
    'yellow-bright': $yellow-bright,
    'grey-silver-light': $grey-silver-light,
    'grey-very-light': $grey-very-light,
    'black-eerie': $black-eerie,
    'grey-generic': $grey-generic,
    'grey-medium': $grey-medium,
    'white-dark': $white-dark,
    'white-flash': $white-flash,
    'grey-platinum': $grey-platinum,
    'purple-medium2': $purple-medium2,
    'purple-lavender': $purple-lavender,
    'granite-gray': $granite-gray,
    'black-darker1': $black-darker1,
    'black-darker2': $black-darker2,
    'black-darker6': $black-darker6,
    'black-light': $black-light,
    'black-extradark': $black-extradark,
    'black-darker': $black-darker,
    'black-dark-jungle': $black-dark-jungle,
    'black-eerie-dark': $black-eerie-dark,
    'black-darklight': $black-darklight,
    'black-verylight': $black-verylight,
    'black-jungle-green': $black-jungle-green,
    'grey-quick-silver': $grey-quick-silver,
    'grey-faux-granite': $grey-faux-granite,
    'grey-very-dark': $grey-very-dark,
    'grey-light-silver': $grey-light-silver,
    'gray-light': $gray-light,
    'white-lotion': $white-lotion,
    'black-verylight-green': $black-verylight-green,
    'blue-jeans': $blue-jeans,
    'blue-navy': $blue-navy,
    'green-medium-dark': $green-medium-dark,
    'green-dark': $green-dark,
    'green-pastel': $green-pastel,
    'orange-dark': $orange-dark,
    'pink-dark': $pink-dark,
    'orange-ripe': $orange-ripe,
    'grey-sonic-silver': $grey-sonic-silver,
    'pink-linen': $pink-linen,
    'yellow-blanched-almond': $yellow-blanched-almond,
    'grey-storm': $grey-storm,
    'blue-denim': $blue-denim,
    'grey-platinum-dark': $grey-platinum-dark,
    'grey-outer-space': $grey-outer-space,
    'black-darker5': $black-darker5,
    'violet-light': $violet-light,
    'purple-dark': $purple-dark,
    'white-anti-flash': $white-anti-flash,
    'blue-light-steel': $blue-light-steel,
    'green-olivine': $green-olivine,
    'orange-yellow': $orange-yellow,
    'gray-default': $gray-default,
    'pink-vivid-tangerine': $pink-vivid-tangerine,
    'purple-pale-lavender': $purple-pale-lavender,
    'gray-iron': $gray-iron,
    'green-crayola': $green-crayola,
    'grey-philippine-silver': $grey-philippine-silver,
    'green-mint-cream': $green-mint-cream,
    'yellow-cosmic-latte': $yellow-cosmic-latte,
    'yellow-cornsilk': $yellow-cornsilk,
    'blue-alice': $blue-alice,
    'gray-brown': $gray-brown,
    'white-light-cultured': $white-light-cultured,
    'pink-orchid': $pink-orchid,
    'red-lavender-blush': $red-lavender-blush,
    'pink-light-salmon': $pink-light-salmon,
    'pink-spanish': $pink-spanish,
    'red-misty-rose': $red-misty-rose,
    'grey-american-silver': $grey-american-silver,
    'orange-seashell-dark': $orange-seashell-dark,
    'white-ghost': $white-ghost,
    'orange-linen': $orange-linen,
    'yellow-cornsilk-dark': $yellow-cornsilk-dark,
    'black-dark-liver': $black-dark-liver,
    'purple-pale': $purple-pale,
    'white-light': $white-light,
    'black-dark-charcoal': $black-dark-charcoal,
    'purple-lavender-darker': $purple-lavender-darker,
    'red-pigment': $red-pigment,
    'orange-deep-saffron': $orange-deep-saffron,
    'green-chinese-white': $green-chinese-white,
    'white-ghost-light': $white-ghost-light,
    'white-ghost-lighter': $white-ghost-lighter,
    'purple-magnolia': $purple-magnolia,
    'blue-alice-light': $blue-alice-light,
    'green-honeydew': $green-honeydew,
    'red-misty-rose-darker': $red-misty-rose-darker,
    'violet-lavender': $violet-lavender,
    'grey-bright': $grey-bright
);

$font-family-sans-serif: 'Inter', sans-serif !default;
$border-none: none;

// Process circle small
$progress-circle-small: 18px;

// Box shadow
$boxshadow-dropdown: 0 10px 15px 0 rgba(0, 0, 0, 0.01), 0 8px 14px 0 rgba(0, 0, 0, 0.02),
    0 4px 12px 0 rgba(0, 0, 0, 0.04), 0 2px 8px 0 rgba(0, 0, 0, 0.06), 0 0 2px 0 rgba(0, 0, 0, 0.08);
$boxshadow-default: 0 3px 6px 0 rgba(0, 0, 0, 0.05), 0 0 4px 0 rgba(0, 0, 0, 0.03), 0 0 1px 0 rgba(0, 0, 0, 0.06);
$boxshadow-hover: 0 24px 37px 0 rgba(0, 0, 0, 0.04), 0 8px 20px 0 rgba(0, 0, 0, 0.12), 0 6px 12px 0 rgba(0, 0, 0, 0.08),
    0 3px 5px 0 rgba(0, 0, 0, 0.06), 0 1px 2px 0 rgba(0, 0, 0, 0.04), 0 0 1px 0 rgba(0, 0, 0, 0.08),
    0 43px 48px -19px rgba(0, 0, 0, 0.19);
$boxshadow-press: 0 6px 8px 0 rgba(0, 0, 0, 0.02), 0 3px 6px 0 rgba(0, 0, 0, 0.04), 0 1px 3px 0 rgba(0, 0, 0, 0.02),
    0 1px 2px 0 rgba(0, 0, 0, 0.03);
$boxshadow-press-inset: inset 0 0 8px 0 rgba(0, 0, 0, 0.08), inset 0 1px 6px 0 rgba(0, 0, 0, 0.03),
    inset 0 2px 4px 0 rgba(0, 0, 0, 0.05);
$boxshadow-dropdown-main: 0 6px 8px -6px rgba(0, 0, 0, 0.12), 0 5px 12px 0 rgba(0, 0, 0, 0.03),
    0 4px 10px 0 rgba(0, 0, 0, 0.03), 0 3px 8px 0 rgba(0, 0, 0, 0.04), 0 2px 6px 0 rgba(0, 0, 0, 0.04),
    0 0 5px 0 rgba(0, 0, 0, 0.08);
$boxshadow-tabitem: inset 0 2px 8px 0 rgba(0, 0, 0, 0.07), inset 0 2px 6px 0 rgba(0, 0, 0, 0.14),
    inset 0 1px 4px 0 rgba(0, 0, 0, 0.04), inset 0 1px 2px 0 rgba(0, 0, 0, 0.02), inset 0 0 1px 0 rgba(0, 0, 0, 0.1);
$boxshadow-yellow: 0 0 1px 2px $boder-color-message, 0 8px 8px 0 rgba(0, 0, 0, 0.03), 0 4px 6px 0 rgba(0, 0, 0, 0.05),
    0 2px 3px 0 rgba(0, 0, 0, 0.03), 0 1px 2px 0 rgba(0, 0, 0, 0.04), 0 0 1px 0 rgba(0, 0, 0, 0.08);
$boxshadow-grey-softlight: 0px 2px 5px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.02),
    0px 0px 1px rgba(0, 0, 0, 0.08), inset 0px -1px 0px $grey-softlight;
$boxshadow-btn-default: inset 0 2px 8px 0 rgba(0, 0, 0, 0.05), inset 0 1px 4px 0 rgba(0, 0, 0, 0.04),
    inset 0 1px 2px 0 rgba(0, 0, 0, 0.02), inset 0 0 1px 0 rgba(0, 0, 0, 0.09);
$boxshadow-dark-gray: 0 8px 22px 0 rgba(42, 54, 65, 0.12), 0 5px 14px 0 rgba(42, 54, 65, 0.05),
    0 3px 6px 0 rgba(42, 54, 65, 0.05);
$boxshadow-gray-outset: 8px 11px 15px 5px rgba(42, 54, 65, 0.12), 3px 3px 15px 0 rgba(42, 54, 65, 0.05),
    5px 8px 10px 0px rgba(42, 54, 65, 0.05);
$boxshadow-btn-danger: 0px 1px 2px rgba(0, 0, 0, 0.02), inset 0px 0px 1px rgba(0, 0, 0, 0.02),
    inset 0px 1px 4px rgba(0, 0, 0, 0.04), inset 0px 2px 8px rgba(0, 0, 0, 0.05);
$boxshadow-btn-active: 0px 2px 5px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.02), 0px 0px 1px rgba(0, 0, 0, 0.08),
    0px 0px 0px 3px $boder-color-message;
$boxshadow-btn-grey: 0px 4px 6px rgba(0, 0, 0, 0.05), 0px 2px 3px rgba(0, 0, 0, 0.03), 0px 1px 2px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.08);
$boxshadow-grey-light: 0px 1px 3px rgba(0, 0, 0, 0.06), 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.08);

//CDN path for image, fonts
$cdnPathImages: 'https://cdn.gorilladesk.com/assets/images/';
$cdnPathFonts: 'https://cdn.gorilladesk.com/assets/fonts/';
