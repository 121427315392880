@import 'src/assets/css/common/variables';
@import 'src/assets/css/common/mixins';

.dashboard-page {
    .header-v2 {
        z-index: 9999;
    }
    &:not(.active) {
        .container-dashboard {
            padding-left: 16px;
        }
    }
    .container-dashboard {
        line-height: 16px;
        font-size: 13px;
        > .scrolls {
            padding: 16px 16px 16px 0px;
        }
        .fs-13 {
            line-height: 16px;
        }
        .fs-26 {
            font-weight: 600;
            line-height: 32px;
        }
        .--label-r {
            text-align: right;
        }
        .wrapper-dashboard {
            &__content {
                @include flexwrap;
                gap: 16px;
                max-width: 1920px;
                margin: 0 auto;
                .boxs {
                    flex: 1 0 28%;
                    min-width: 470px;
                    &.--full {
                        flex-basis: 98%;
                    }
                    &.--half {
                        &,
                        & + .boxs:not(.--full) {
                            flex-basis: 48%;
                        }
                    }
                    .field-date {
                        .txt-ellipsis + .arrow {
                            margin-left: 4px;
                        }
                    }
                }
            }
        }
        .wrapper-widget {
            @include flextop;
            flex-wrap: wrap;
            width: 100%;
            gap: 16px;
            > .col {
                @include flexcolumns;
                gap: 16px;
                flex: 1;
                min-width: 480px;
                @media (min-width: 499px) {
                    .boxs__content {
                        min-height: 480px;
                    }
                }
            }
        }
        .boxs {
            border: 1px solid $border-color-grey;
            background-color: $white;
            border-radius: 4px;
            box-shadow: 0px 0px 1px 0px $black-extradark, 0px 0px 2px 0px $black-light, 0px 0px 3px 0px $black-verydark;
            @include flexcolumns;
            &__header {
                @include flexcenter;
                flex-wrap: wrap;
                gap: 8px;
                padding: 8px 16px;
                background-color: $black-light;
                border-bottom: 1px solid $border-color-grey;
                border-radius: 3px 3px 0 0;
                .header-label {
                    @include font-color(32px, 14px, $black);
                    @include text-ellipsis;
                    font-weight: 600;
                }
                .react-datepicker-wrapper {
                    margin-left: auto;
                    & + .react-datepicker__tab-loop {
                        margin-left: -8px;
                    }
                    .field-date.active {
                        .arrow {
                            @include transform(rotateZ(180deg));
                            margin-top: 3px;
                        }
                    }
                }
                .react-datepicker__tab-loop {
                    .filter-range {
                        &__date {
                            .range-label,
                            .range-day {
                                line-height: 20px;
                            }
                        }
                    }
                    .react-datepicker__day:focus-visible {
                        outline: none;
                    }
                }
            }
            &__content {
                height: 100%;
                &.--progress-bar {
                    .is-summary {
                        box-shadow: inset 0 -1px 0 $border-color-grey;
                    }
                }
                &.is-summary {
                    .rows {
                        padding: 16px;
                        .col {
                            gap: 6px !important;
                            padding: 16px 0 16px 32px !important;
                        }
                    }
                }
                &:has(.is-summary) {
                    .dashboard-table tbody {
                        height: 400px;
                    }
                }
            }
            .v2-dropdown {
                .dropbtn {
                    background-color: $white;
                }
            }
            .dropdown-listname {
                .v2-dropdown__menu {
                    right: auto;
                    z-index: 3;
                    .status-dropdown {
                        .check-items {
                            input[type='checkbox']:checked + .item-checkbox label::after {
                                top: 8px;
                            }
                            label::before {
                                top: 4px;
                            }
                            .user-name {
                                width: 100%;
                                gap: 6px;
                            }
                            .avt-img {
                                margin-right: 0;
                                padding-left: 0;
                                & + .txt-ellipsis {
                                    padding-left: 0;
                                }
                            }
                        }
                    }
                    .items {
                        padding: 4px 6px;
                    }
                }
            }
            .is-summary {
                .rows {
                    @include flexcenter;
                    .col {
                        @include flexcolumns;
                        @include text-ellipsis;
                        padding: 14px 0 14px 16px;
                        position: relative;
                        gap: 4px;
                        flex: 1;
                        > .price,
                        > .flexcenter {
                            & + .fs-13 {
                                color: $grey-generic;
                            }
                        }
                        > .flexcenter {
                            flex-wrap: wrap;
                            .dot {
                                @include size(6px);
                                border-radius: 50%;
                                margin: 0 6px;
                                background-color: $grey-soft;
                                & + .fs-26 {
                                    max-width: calc(100% - 18px);
                                }
                            }
                        }
                        &:first-child {
                            border-radius: 8px 0 0 8px;
                        }
                        &:nth-child(3) {
                            border-radius: 0 8px 8px 0;
                        }
                        &:not(:nth-child(3)):not(:last-child):after {
                            content: '';
                            position: absolute;
                            width: 1px;
                            height: 48px;
                            top: 20%;
                            right: 0;
                            border-right: 1px solid $black-extradark;
                        }
                    }
                }
            }
            .chart {
                @include flexcolumns;
                height: 100%;
                &__content {
                    @include betweentop;
                    gap: 16px;
                    padding: 16px;
                    .is-summary {
                        .rows {
                            .col {
                                padding: 0;
                            }
                        }
                    }
                    &,
                    > .flex-column {
                        @include size(100%);
                    }
                    &.--job-status {
                        @media (min-width: 501px) {
                            max-height: 220px;
                            overflow-y: auto;
                            position: relative;
                            padding-top: 0;
                            .is-summary, .content-r {
                                position: sticky;
                            }
                            .is-summary {
                                left: 0;
                                top: 0;
                                background-color: $white;
                                z-index: 2;
                                padding: 16px 0;
                            }
                            .content-r {
                                top: 24px;
                                right: 0;
                            }
                            .content-l {
                                gap: 0;
                            }
                        }
                        @media (max-width: 500px) {
                            padding: 16px 0 0;
                            .is-summary,
                            .content-r {
                                padding: 0 16px;
                            }
                            .wrap-rows {
                                padding: 0 16px 16px;
                                max-height: 220px;
                                overflow-y: auto;
                            }
                        }
                    }
                }
                &__footer {
                    @include flexcolumns;
                    padding: 16px;
                    gap: 2px;
                    border-top: 1px solid $black-extradark;
                }
                .wrap {
                    flex-wrap: wrap;
                    gap: 8px;
                    > .flexcenter {
                        overflow: hidden;
                    }
                }
                .content-l {
                    @include flexcolumns;
                    gap: 24px;
                    flex: 1;
                    max-width: calc(100% - 200px);
                    .wrap-rows {
                        @include flexcolumns;
                        gap: 6px;
                        .rows {
                            @include betweenitems;
                            gap: 8px;
                            &__label {
                                @include flexcenter;
                                overflow: hidden;
                                gap: 4px;
                                flex: 1;
                                .txt-ellipsis {
                                    line-height: 16px;
                                }
                            }
                            &__percent {
                                width: 35px;
                                color: $granite-gray;
                            }
                            &__job {
                                width: 25%;
                                color: $granite-gray;
                            }
                            &__price {
                                width: 30%;
                                font-weight: 600;
                            }
                        }
                    }
                }
                .content-r {
                    margin: auto 0;
                }
                .dots-color {
                    flex: 0 0 auto;
                    @include size(8px);
                    border-radius: 22px;
                }
                .chartjs__content {
                    height: 100%;
                    .line-chart-large {
                        min-height: 350px;
                    }
                }
            }
        }
        .dashboard-table {
            border-collapse: collapse;
            width: 100%;
            display: block;
            tr {
                display: table-row;
                box-shadow: inset 0 -1px 0 $border-color-grey;
                &.row-total {
                    background-color: $white;
                    position: sticky;
                    top: 100%;
                    left: 0;
                    border-radius: 0px 0px 4px 4px;
                    border-bottom: 0;
                    box-shadow: inset 0 1px 0 $border-color-grey;
                    td {
                        width: 100%;
                        &:first-child {
                            border-radius: 0px 0px 0px 4px;
                        }
                        &:last-child {
                            border-radius: 0px 0px 4px 0px;
                        }
                    }
                }
                &:has(.col-number + .fs-11) {
                    td:has(.progress-bar) {
                        width: 50%;
                    }
                }
            }
            td {
                padding: 0px 12px;
                height: 40px;
                display: table-cell;
                width: 10%;
                @include text-ellipsis;
                &:last-child {
                    font-weight: 600;
                    padding-right: 16px;
                    max-width: 130px;
                }
                &.col-number {
                    max-width: 70px;
                }
                &:first-child {
                    padding-left: 16px;
                    width: 30%;
                    max-width: 250px;
                    .col__title {
                        @include flexcenter;
                        gap: 6px;
                        .col-label {
                            @include text-ellipsis;
                            width: calc(100% - 30px);
                        }
                    }
                }
                &:has(.progress-bar) {
                    width: 60%;
                    .progress-bar {
                        min-width: 50px;
                    }
                }
            }
            tbody {
                height: 480px;
                overflow-y: auto;
                display: block;
                width: 100%;
                position: relative;
                &:has(tr:nth-of-type(10)) {
                    .row-total {
                        bottom: 0;
                        top: unset;
                    }
                    &:has(.row-total) {
                        tr:nth-last-child(2) {
                            box-shadow: none;
                        }
                    }
                    tr:not(.row-total):last-child {
                        box-shadow: none;
                    }
                }
                @media (max-width: 500px) {
                    &:has(tr:nth-of-type(5)) {
                        .row-total {
                            bottom: 0;
                            top: unset;
                        }
                    }
                }
            }
            .progress-bar {
                background: $blue-light;
                height: 4px;
                > span {
                    height: 4px;
                }
            }
        }
    }
}
@media only screen and (max-width: 653px) {
    .gorilladesk:not(.auth-page):has(.container-dashboard) {
        overflow-x: hidden !important;
        width: 100% !important;
        .horizontal-menu {
            display: none !important;
        }
        .container {
            &__header {
                &.header-v2-right.h-100 > :not(.nav-user) {
                    display: none !important;
                }
                .dropbtn {
                    .nav__label {
                        font-size: 11px;
                    }
                }
                .nav-menu {
                    .v2-dropdown {
                        &__menu {
                            .wrap-nav-list {
                                .--list-addons,
                                .bg-feauture {
                                    display: none;
                                }
                                .--list-menu {
                                    min-width: 300px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .dashboard-page {
        .container-dashboard {
            .boxs {
                &.--full {
                    max-width: 100%;
                }
                &__content {
                    &.--progress-bar {
                        .dashboard-table {
                            tbody {
                                max-height: 200px;
                            }
                            td {
                                &:last-child {
                                    max-width: 100%;
                                }
                                &.col-number {
                                    max-width: 100%;
                                }
                                &:has(.progress-bar) {
                                    width: 10%;
                                }
                            }
                            @media only screen and (max-width: 500px) {
                                td {
                                    &:has(.progress-bar) {
                                        .progress-bar {
                                            min-width: 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.is-summary {
                        .rows {
                            flex-direction: column;
                            .col {
                                width: 100%;
                                padding: 16px !important;
                                &:first-child {
                                    border-radius: 8px 8px 0px 0px;
                                }
                                &:nth-child(3) {
                                    border-radius: 0px 0px 8px 8px;
                                }
                                &:not(:nth-child(3)):not(:last-child):after {
                                    border-right: 0px;
                                }
                                &:not(:nth-child(3)):not(:last-child):after {
                                    width: 100%;
                                    top: 44%;
                                    left: 0;
                                    border-bottom: 1px solid $black-extradark;
                                }
                            }
                        }
                    }
                }
            }
            @media only screen and (max-width: 600px) {
                .wrapper-dashboard {
                    &__content {
                        .boxs {
                            min-width: 100%;
                            .chart {
                                &__content {
                                    &:has(.content-l) {
                                        flex-direction: column-reverse;
                                        align-items: center;
                                        .content-l {
                                            max-width: 100%;
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                        .wrapper-widget {
                            > .col {
                                min-width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
