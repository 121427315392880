@import 'src/assets/css/common/variables';
@import 'src/assets/css/common/mixins';

.auth-page {
    .wrapper {
        @include size(100%);
        background: $bg-body;
        overflow-y: auto;
        @include flexcolumns;
        .main-login {
            width: 100% !important;
            width: max-content;
            position: relative;
            @include flexcolumns;
            align-items: center;
            justify-content: space-between;
            flex: 1;
        }

        .gd-form-v2 {
            margin-top: 50px;
            max-width: 480px;
            padding: 0 40px;
            opacity: 1;
            box-sizing: unset;
            background-color: $white;
            border-radius: 8px;
            border: solid 2px $white-flash;
            @include transitions(opacity 400ms ease-in-out);

            h2 {
                padding: 40px 0 30px;
                text-align: center;
                text-transform: capitalize;
            }

            @media screen and (max-width: 500px) {
                &:not(.social-form) {
                    padding: 0 8px !important;
                    width: 95%;
                }
            }
        }

        .flex {
            display: flex;
            @media screen and (max-width: 480px) {
                display: block;
            }
        }

        .gd-line {
            padding: 25px 41px 10px;
            .gd-login-line {
                position: relative;
                height: 24px;
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    margin-top: -3px;
                    width: 100%;
                    height: 1px;
                    background: $white-flash;
                }
                span {
                    display: block;
                    width: 35px;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    margin: auto;
                    padding: 0;
                    text-align: center;
                    background: $white;
                    color: #a0adbe;
                    font-size: 14px;
                }
            }
        }

        .gd-login-btn {
            margin-bottom: 20px;
        }

        .gd-login-btn-group {
            padding: 0;
            .user-box {
                margin-bottom: 12px;
            }

            label {
                color: $black-3;
                line-height: 1.6;
            }

            input {
                width: 100%;
                height: 40px;
                color: $grey-extradark;
                outline: none;
                border: 1px solid #d4dae2;
                padding: 0px 12px;
                margin-bottom: 0;
                @include transitions(0.1s ease-in-out);
            }
        }

        .gd-getstarted {
            margin-top: 20px;
            justify-content: center;
        }

        .gd-btn-getstarted {
            height: 40px;
            width: 100%;
            border-radius: 4px;
            font-size: 15px;
        }

        .gd-login-label {
            color: $violet-name;
            font-size: 13px;
            font-weight: 500;
            line-height: 20px;
            margin-left: 4px;
            &:hover {
                text-decoration: underline;
                opacity: 0.8;
            }

            &.--forgot {
                display: block;
                text-align: right;
                margin-top: 4px;
            }
        }

        .gd-login-confirm {
            padding: 15px 0 45px;
            text-align: center;
            color: $black-3;
            border: 1px solid #d1d1d1;
            font-size: 13px;
            font-weight: 500;
        }

        .mascot {
            margin-top: 5px;
            flex: 0 0 auto;
            img {
                object-fit: contain;
                max-height: 150px;
                width: 100%;
            }
        }

        .social-form {
            padding: 40px;

            .social-desc {
                line-height: 20px;
                word-break: break-word;
            }

            .v2-btn-main {
                margin-top: 20px;
                width: 100%;
                justify-content: center;
                font-weight: 500;
            }

            @media screen and (max-width: 480px) {
                padding: 32px;
            }
        }

        .box-success {
            text-align: center;
            flex: 1;
            width: 100%;
            @include centeritem;
            &__desc {
                color: $black-3;
                font-weight: 500;
                font-size: 16px;
                line-height: 28px;
            }
            &__gif {
                display: block;
                margin: 25px auto;
            }
            &__btn {
                padding: 25px 50px;
                border-radius: 8px;
                font-size: 15px;
            }
            &__mascot {
                margin: 35px auto;
            }
            .gd-form-v2 {
                @media (max-width: 490px) {
                    padding: 20px !important;
                }
            }
            .modal-confirm-delete {
                text-align: left;
                .modal__container {
                    @media (max-width: 450px) {
                        width: 100%;
                    }
                    .body-modal {
                        border-top-color: $border-color-grey;
                        padding: 20px 23px;
                    }
                }
                .v2-btn-main {
                    width: unset;
                    height: 32px;
                    & > svg {
                        margin: 0 5px 0 -6px;
                        path {
                            stroke: $white;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 500px) {
            .main-login {
                width: 100%;
            }
        }
    }

    #recaptcha_wrapper {
        text-align: center;
        margin: 20px auto 0;
        max-width: 100%;

        .g-recaptcha {
            display: inline-block;

            > div:first-child {
                width: auto !important;
            }
        }
    }

    .logo-text-gorilla {
        padding: 40px 20px 25px;
        text-align: center;
        img {
            object-fit: contain;
        }
    }

    h1 {
        margin: 0;
        text-align: center;
    }

    .message {
        @include centeritem;
        border-radius: 6px;
        min-height: 44px;
        padding: 10px;
        word-break: break-word;

        &.--success {
            background-color: $green-default;
            color: $white;
        }

        &.--fail {
            background-color: rgba(238, 70, 70, 0.12);
            color: $red-fire-opal;
        }

        p {
            font-size: 15px;
            text-align: center;
        }

        @media (min-width: 501px) {
            margin-top: 30px !important;
        }
    }

    .login-action {
        @include flexcenter;
        flex-direction: row-reverse;
        gap: 8px;
        &-btn {
            width: calc(50% - 4px);
            padding-right: 8px;
            cursor: pointer;
            height: 40px;
            @include centeritem;
            font-weight: 500;
            border-radius: 4px;
            border: 1px solid $very-light-gray;
            box-shadow: 0px 0px 1px 0px $black-extradark, 0px 1px 2px 0px $black-light, 0px 1px 3px 0px $black-verydark;

            &:hover {
                border-color: $grey-quick-silver;
            }

            &__label {
                text-align: center;
                white-space: nowrap;
                color: $black;
            }

            &__icon {
                svg {
                    @include size(32px);
                }
            }
        }
        @media (max-width: 500px) {
            .login-action-btn {
                font-size: 12px;
                svg {
                    @include size(24px);
                }
                &__label {
                    @include text-ellipsis;
                }
            }
        }
        @media (max-width: 400px) {
            .login-action {
                gap: 4px;
            }
            .login-action-btn {
                font-size: 10px;
                padding-right: 4px;
            }
        }
    }

    .auth-container {
        overflow-y: hidden;
        .gd-form-v2 {
            background-color: rgba($white, 0.95);
        }
        .logo-text-gorilla {
            padding: 20px 10px 16px 0px;
        }
        .main-login {
            gap: 70px;
            overflow-y: auto;
            & > .flex-centeritem {
                width: 100%;
            }
        }
        .gd-line {
            padding: 25px 0 15px;
            .line {
                width: 45%;
            }
        }
        .gd-login-line::before {
            background: $very-light-gray !important;
        }
        .line {
            border-top: 1px solid $very-light-gray;
        }
        .gd-login-confirm {
            padding: 25px 0;
            border-color: $very-light-gray;
            margin-bottom: 20px;
            border-radius: 4px;
        }
        .banner {
            .noise svg {
                @media (max-width: 900px) {
                    height: 100%;
                }
                @media only screen and (max-height: 500px) and (orientation: landscape) {
                    display: none;
                }
            }
        }
    }

    // Multi step
    .multi-step {
        display: flex;
        gap: 8px;
        margin-bottom: 20px;
        .step {
            height: 8px;
            border-radius: 50px;
            background-color: rgba($black, 0.08);
            flex-grow: 1;
            cursor: pointer;
            &.active {
                background-color: $purple-default;
            }
        }
    }
    .container-tabs {
        .field-input,
        .v2-dropdown .dropbtn {
            height: 40px;
        }
        .--area-code {
            width: 110px;
        }
        .v2-dropdown {
            .drop-container {
                max-height: unset;
                .drop-items__title {
                    cursor: unset;
                }
            }
        }
        .has-form:has(.g-recaptcha) {
            align-items: center;
        }
    }
    .authentication-items {
        padding: 10px;
        cursor: pointer;
        &:hover,
        &:active {
            color: $grey-extradark;
            background-color: $btn-hover;
        }
    }
    .authentication-page {
        .main-login {
            max-width: 410px;
        }
    }
}
