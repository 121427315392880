@import 'src/assets/css/common/variables';
@import 'src/assets/css/common/mixins';
.onboarding-page {
    background-color: $white-anti-flash;
    .sidebar-container {
        border-right: 1px solid $grey-light-silver;
    }
    .container-wrap {
        padding: 16px 0 0 16px;
        font-size: 13px;
        &.--trial::before {
            content: '';
            position: absolute;
            background: url(#{$cdnPathImages}rocket.webp) bottom center no-repeat;
            background-size: 300px auto;
            width: 300px;
            height: 100%;
            left: 0;
            bottom: 0;
        }
    }
    .onboarding-sidebar {
        width: 300px;
        padding-bottom: 16px;
    }
    .onboarding-container {
        width: calc(100% - 316px);
        padding: 0 16px 16px 0;
        .onboarding-items {
            max-width: 1400px;
            margin: 0 auto;
            @media (min-width: 1025px) {
                min-width: 900px;
            }
        }
    }
    .onboarding-items {
        box-shadow: 0px 0px 1px 0px $black-extradark, 0px 0px 2px 0px $black-light, 0px 0px 3px 0px $black-verydark;
        border-radius: 4px;
        background-color: $white;
        & + .onboarding-items {
            margin-top: 8px;
        }
        &:not(.questlog-container) {
            padding: 16px;
        }
    }
    .onboarding-steps,
    .onboarding-video {
        width: calc(50% - 8px);
    }
    .step-items {
        padding: 8px;
        border: 1px solid $black-dark-jungle;
        border-radius: 4px;
        background-color: $white-dark; 
        height: 50px;
        cursor: pointer;
        &:hover,
        &:active {
            box-shadow: $boxshadow-btn-grey;
            border-color: $black-darker;
        }
        &.active {
            background-color: $btn-press;
        }
        &__icon {
            border: 1px solid rgba($purple-default, 0.2);
            background-color: rgba($purple-default, 0.04);
            border-radius: 50%;
            @include centeritem;
            @include size(32px);
        }
    }
    .onboarding-video__iframe {
        width: 100%;
        border: 0;
        height: 339px;
    }
    .question-items {
        border: 1px solid $black-dark-jungle;
        box-shadow: $boxshadow-grey-light;
        border-radius: 4px;
        background-color: $white-dark; 
        height: 40px;
        padding: 8px 10px 8px 16px;
        flex-grow: 1;
        width: 32%;
        &:hover,
        &:active {
            border-color: $black-darker;
            box-shadow: $boxshadow-btn-grey;
        }
    }
    .onboarding-library {
        padding: 16px 0 0 0 !important;
        & > * {
            padding: 0 16px;
        }
        .library-list {
            overflow: auto;
            padding-bottom: 16px;
        }
        .library-items {
            min-width: 276px;
            width: 276px;
            .library-iframe {
                width: 100%;
                height: 150px;
                border: 0px;
            }
        }
    }
    @media (max-width: 1024px) {
        width: 1300px !important;
        .container-wrap {
            max-width: 100% !important;
        }
    }
}

// modal onboarding question 
.modal.modal-onboarding-question {
    .modal__container {
        max-width: 650px;
    }
    .header-modal {
        height: 88px;
        .v2-btn-main {
            height: 40px;
        }
        .v2-btn-default {
            @include size(40px);
        }
    }
    .onboarding-iframe {
        border: 0;
        width: 100%;
        min-height: 340px;
    }
}

.questlog-container {
    color: $black;
    .items {
        @include flexcenter;
        min-height: 32px;
        justify-content: space-between;
        padding: 0px 6px;
        margin-top: 4px;
        border-radius: 4px;
        cursor: pointer;
        > .txt {
            @include text-ellipsis;
            display: inline-block;
            width: calc(100% - 30px);
        }
        svg {
            opacity: 0.32;
        }
        &:not(.disable):hover {
            color: $grey-extradark;
            background-color: $btn-hover;
            svg {
                opacity: 1;
                path {
                    stroke: $purple-default;
                }
            }
        }
        &.disable {
            color: $black-7 !important;
            cursor: default;
        }
        .tooltiptext {
            right: 0;
            transform: none;
            left: unset;
        }
    }
    .title {
        padding: 16px 11px 10px;
    }
    .accordion {
        padding: 0 11px 16px;
        &-item {
            cursor: pointer;
            margin: 10px 5px 0;
            .status-btn {
                @include flexcenter;
                border-radius: 9px;
                margin-left: 4px;
                padding: 0 6px 0 0px;
                font-weight: 500;
                svg {
                    margin-top: -2px;
                }
            }
        }
        &-panel {
            display: none;
            .items {
                &.completed {
                    color: $date-color;
                    opacity: 1;
                    .txt {
                        text-decoration: line-through;
                    }
                }
            }
            .check-items {
                margin-right: 5px;
                margin-top: -30px;
                line-height: inherit;
            }
        }
        .chevron {
            @include size(24px);
            @include centeritem;
        }

        &.active {
            .chevron {
                @include transform(rotateZ(180deg));
                svg {
                    margin-top: -3px;
                }
            }
        }
        .range-percent {
            color: $green-default;
            font-weight: 500;
            @include flexcenter;
            &.is-zero {
                color: $black-7;
            }
        }
    }
    .line {
        border-top: solid 1px rgba($black, 0.12);
        width: 100%;
    }
}