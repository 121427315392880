@import 'src/assets/css/common/variables';
@import 'src/assets/css/common/mixins';

.clock-page {
    &:not(.active) {
        .container-wrap {
            padding-left: 16px;
        }
    }
    .container-wrap {
        padding-right: 16px;
    }
    .wrapper-clock {
        @include flexcolumns;
        height: 100%;
        &__header {
            @include flexcenter;
            gap: 4px;
            padding: 16px 0;
            background-color: $white-anti-flash;
        }
        &__content {
            @include flexcolumns;
            flex: 1;
        }
        &__footer {
            @include betweenitems;
            padding: 8px;
            background-color: $white;
            height: 48px;
            position: fixed;
            width: calc(100% - 64px);
            right: 0;
            bottom: 0;
        }
    }
    .wrap-time-tracker {
        @include flexcenter;
        flex: 1;
        background-color: $white;
        padding: 8px;
        gap: 8px;
        border-radius: 4px;
        max-height: 48px;
        border: 1px solid $grey-verylight;
        box-shadow: 0px 0px 1px 0px $black-extradark, 0px 0px 2px 0px $black-light, 0px 0px 3px 0px $black-verydark;

        .time {
            padding: 0 6px;
            border-radius: 4px;
            border: 1px solid rgba($color: $black-jungle-green, $alpha: 0.2);
            box-shadow: 0px 0px 1px 0px $black-extradark, 0px 1px 2px 0px $black-light, 0px 1px 3px 0px $black-verydark;
            text-align: center;
            font-weight: 600;
            font-size: 18px;
        }
        .time {
            height: 32px;
            line-height: 32px;
        }
        .wrap-time {
            .field-input {
                max-width: 110px;
                text-align: center;
                padding: 0 5px;
                flex: 1;
            }
        }
        .search-input {
            max-width: 245px;
        }
        .tabs {
            .tab-items {
                padding: 0 8px 0 4px;
                &.active-tab-selector {
                    &:first-child {
                        svg path {
                            fill: $white;
                        }
                    }
                    &:last-child {
                        svg path {
                            stroke: $white;
                        }
                    }
                }
            }
        }
    }
    &:has(.wrap-time .v2-dropdown.active),
    &:has(.--menu > .v2-dropdown.active) {
        .header-v2 {
            z-index: 100003 !important;
        }
        .trial-bar,
        .notify-bar,
        .wrap-connection,
        .wrapper-clock__header {
            z-index: 100001 !important;
        }
    }
}
