// Btn main
.v2-btn-main {
    background-color: $purple-default;
    border-color: $purple-default;
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
    height: 32px;
    cursor: pointer;
    color: $white;
    padding: 6px 14px;
    @include flexinline;
    &:not(.inactive):not(.disable):hover {
        background-color: $purple-hover;
        color: $white !important;
    }
    &:active {
        background-color: $purple-active;
    }
    &.has-icon {
        padding: 0px 10px 0px 4px;
        svg {
            margin-right: 4px;
        }
    }
    &.--icon-r {
        padding-right: 4px;
        padding-left: 10px;
        svg {
            margin-left: 4px;
        }
    }
    &.disable {
        opacity: 0.5;
        cursor: not-allowed;
    }
    &.--icon {
        &-sm,
        &-lg {
            padding: 0px;
            border-radius: 4px;
            @include centeritem;
        }
        &-sm {
            @include size(24px);
        }
        &-lg {
            @include size(32px);
        }
    }
    &.--bg-green {
        background-color: $green-default;
        border-color: $green-default;
        &:not(.inactive):not(.disable):hover {
            background-color: $green-hover;
        }
        &:active {
            background-color: $green-active;
        }
    }
    &.--bg-orange {
        background-color: $orange-default;
        border-color: $orange-default;
        &:not(.inactive):not(.disable):hover {
            background-color: $orange-hover;
        }
        &:active {
            background-color: $orange-active;
        }
    }
    &.--sm {
        height: 24px;
        line-height: 24px;
    }
    &.--large {
        height: 40px;
        line-height: 40px;
    }
    &.btn-onboarding {
        padding-left: 16px;
        padding-right: 10px;
        svg {
            margin-left: 10px;
        }
    }
}
.btn-bg-overlay {
    @include size(24px);
    @include centeritem;
    background-color: #000000b2;
    border-radius: 4px;
    cursor: pointer;
}
.v2-btn-default {
    border-radius: 4px;
    cursor: pointer;
    color: $black-3;
    font-size: 14px;
    font-weight: 500;
    height: 32px;
    padding: 0 8px;
    text-align: center;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    width: auto;
    border: 1px solid $transparent;
    &:not(.--transparent):not(.--purple):not(.--noborder):not(.loading):not(.btn-blocked):not(.--delete):not(
            .btn-magnet
        ) {
        background-color: $white;
        border-color: $black-dark-jungle;
        box-shadow: $boxshadow-grey-light;
    }
    &:hover:not(.btn-today):not(.--delete):not(.--purple):not(.selected):not(.--loadmore):not(.btn-syned):not(
            .is-delete
        ):not(.loading):not(.remove):not(.active):not(.btn-blocked) {
        border-color: $black-darker;
        box-shadow: $boxshadow-btn-grey;
    }
    &:active:not(.--noborder):not(.btn-syned):not(.loading):not(.--delete):not(.active):not(.btn-blocked) {
        box-shadow: $boxshadow-btn-default;
        background-color: rgba(0, 0, 0, 0.01);
    }
    &.has-icon {
        padding: 4px 8px 4px 4px;
        > svg {
            margin-right: 4px;
        }
    }
    &.selection {
        padding: 0 6px 0px 10px;
        &.has-icon {
            padding-left: 4px;
        }
    }
    &.just-icon {
        @include size(32px);
        padding: 0px;
        box-shadow: none !important;
    }
    &.--noborder {
        border-color: $transparent;
        &:not(.just-icon):not(.--label) {
            padding: 0px 10px 0px 4px;
            svg {
                margin-right: 4px;
            }
        }
    }
    &.--purple {
        border-color: $transparent;
        color: $violet-name;
        &:hover {
            border-color: $violet-name;
        }
    }
    &.btn-bg-purple {
        background: rgba(141, 74, 252, 0.06) !important;
        color: $purple-default;
        &:not(.border-purple-default) {
            border-color: rgba(141, 74, 252, 0.16) !important;
        }
        &.btn-lead {
            max-width: 120px;
            svg {
                min-width: 24px;
            }
        }
    }
    &.--grey {
        border-color: $border-color-grey !important;
    }
    &.--icon-r {
        padding-right: 4px;
        > svg {
            margin-left: 4px;
        }
    }
    &.has-bg-red,
    &.--delete {
        border-color: $transparent !important;
        color: $red-default;
        &:hover {
            color: $red-hover;
        }
        &:active {
            color: $red-active;
        }
        svg path {
            stroke: $red-default;
        }
    }
    &.--delete {
        &:hover,
        &:active {
            background-color: $red-extralight;
        }
        &:active {
            box-shadow: $boxshadow-btn-danger;
        }
        &.active {
            background-color: $white;
            box-shadow:
                0px 2px 5px rgba(0, 0, 0, 0.05),
                0px 2px 2px rgba(0, 0, 0, 0.02),
                0px 0px 1px rgba(0, 0, 0, 0.08),
                0px 0px 0px 3px $red-light;
        }
    }
    &.has-bg-red {
        background-color: $red-extralight !important;
        &:hover,
        &:active {
            background-color: rgba(238, 70, 70, 0.09) !important;
        }
        &:active {
            box-shadow: $boxshadow-btn-danger !important;
        }
    }
    &.--transparent {
        border-color: $transparent;
    }
    &.--icon {
        &-sm,
        &-lg {
            padding: 0px;
            border-radius: 4px;
            @include centeritem;
        }
        &-sm {
            @include size(24px);
        }
        &-lg {
            @include size(32px);
        }
    }
    &.--sm {
        height: 24px;
        line-height: 24px;
        width: max-content;
    }
    &.has-bg-grey {
        background-color: rgba(28, 31, 34, 0.06) !important;
    }
    &.btn-bg-grey,
    &.has-bg-grey {
        border-color: $transparent !important;
        &:hover {
            background-color: $black-verydark !important;
        }
        &:active {
            box-shadow: $boxshadow-btn-danger;
        }
    }
    &.btn-bg-blue {
        border-color: $transparent !important;
        &:hover {
            background: rgba(30, 126, 247, 0.1);
        }
        &:active {
            box-shadow: $boxshadow-btn-danger;
            background: rgba(30, 126, 247, 0.1) !important;
        }
    }
    &.has-bg-blue {
        background-color: $blue-default !important;
    }
    &.--blue {
        &:hover,
        &:active {
            background-color: $blue-default !important;
            border-color: $blue-default !important;
        }
    }
    &.--has-blue {
        color: $blue-default;
        border-color: rgba($color: $blue-default, $alpha: 0.5) !important;
        background-color: rgba($color: $blue-default, $alpha: 0.06) !important;
        &.has-icon {
            svg path {
                stroke: $blue-default;
            }
        }
        &:hover {
            color: $blue-default !important;
            border-color: rgba($color: $blue-default, $alpha: 0.5) !important;
        }
        svg path {
            stroke: $blue-default;
        }
    }
    &.btn-blocked {
        width: 65px;
        background-color: $red-default;
        color: $white;
        border-color: $red-default !important;
        &:active {
            background-color: $red-coral;
            box-shadow: $boxshadow-grey-light;
        }
    }
    &.--small {
        height: 18px;
        line-height: 18px;
        font-size: 12px;
        padding: 0 5px;
    }
    &.--large {
        height: 40px;
        line-height: 40px;
    }
}
// Button has loading
.--loader {
    text-align: unset;
    cursor: progress;
}
// For all status
.status-btn {
    height: 24px;
    width: auto;
    border-radius: 4px;
    color: $white;
    padding: 0px 6px;
    text-align: center;
    display: inline-block;
    line-height: 24px;
    &:not(.na) {
        text-transform: capitalize;
    }
    &.--sm {
        border-radius: 39px;
        font-size: 11px;
        height: 20px;
        line-height: 20px;
    }
    &.running,
    &.subscribed,
    &.lead,
    &.confirmed,
    &.paid,
    &.won,
    &.won-inv,
    &.signed,
    &.available,
    &.active,
    &.opened,
    &.clicked,
    &.invoiced,
    &.incomplete-expired,
    &.synced,
    &.ok,
    &.green,
    &.growth-plan,
    &.resolved {
        background-color: $green-default;
    }
    &.reschedule,
    &.pending,
    &.pending-esign,
    &.pending_sig,
    &.pending-sig,
    &.partial.paid,
    &.past-due,
    &.bounced {
        background-color: $orange-default;
    }
    &.unsubscribed,
    &.canceled,
    &.failed,
    &.fail,
    &.lost,
    &.void,
    &.rejected,
    &.error,
    &.deleted,
    &.not-resolved {
        background-color: $red-default;
    }
    &.complete,
    &.completed,
    &.write-off,
    &.email,
    &.write.off,
    &.emailed {
        background-color: $light-blue;
    }
    &.pending-confirm,
    &.partial,
    &.agent-resolved {
        background-color: $yellow-default;
    }
    &.unpaid,
    &.grey,
    &.unconfirmed,
    &.na,
    &.auto,
    &.available-other,
    &.inactive,
    &.added,
    &.edited,
    &.created,
    &.updated,
    &.undeleted,
    &.status {
        background-color: $grey-softlight;
        color: $grey-extradark !important;
    }
    &.trialing,
    &.sent,
    &.send,
    &.recurrence {
        background-color: $btn-hover-black;
    }
    &.incompleted,
    &.incomplete,
    &.draft,
    &.schedule {
        background-color: $grey-dark;
    }
    &.pending-booking {
        background-color: $pink-default;
    }
    &.--pro {
        background: $orange-default;
    }
    &.lozenge {
        height: 16px;
        line-height: 16px;
        font-size: 11px;
    }
    &.has-icon {
        height: 24px;
        padding-left: 0;
        @include flexcenter;
    }
    &.--beta {
        background-color: $blue-default;
        height: 20px !important;
        line-height: 20px;
    }
}
.btn-loadmore {
    margin-top: 5px;
    width: 100%;
    height: 40px;
    @include centeritem;
    cursor: default;
    &__label {
        opacity: 0.8;
    }
    &.has-loading:hover {
        .btn-loadmore__label {
            cursor: pointer;
            opacity: 1;
        }
    }
}
.btn-disable {
    background: #fafafa !important;
    color: #cccccc !important;
}
// btn-primary
.wrap-btn-primary {
    &:hover .btn-primary {
        box-shadow: $boxshadow-btn-active;
        border-color: $transparent;
        background-color: $white;
        svg path:last-child {
            fill: $yellow-default;
        }
    }
}
// Update css
.btn-primary {
    background-color: $green-primary;
    cursor: pointer;
    &.--icon {
        @include size(32px);
        @include centeritem;
        border-radius: 12px;
    }
}
.wrap-btn-default {
    border: 1px solid $black-dark-jungle;
    border-radius: 4px;
    box-shadow: $boxshadow-grey-light;
    overflow: hidden;
    .v2-btn-default {
        border-radius: 0;
        &:hover {
            border-color: $transparent !important;
            box-shadow: none;
            background-color: $grey-extralight;
        }
    }
    .is-divider {
        height: 32px !important;
    }
}
