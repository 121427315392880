@import 'src/assets/css/common/variables';
@import 'src/assets/css/common/mixins';

.settings-page {
    &:not(.active) {
        .container-wrap {
            position: relative;
        }
    }
    .container-setting {
        display: flex;
        overflow-y: hidden !important;
        &.custom-grid-sidebar {
            grid-template-columns: auto 1fr;
        }
        .contents-pages {
            &.setting-schedules {
                .tables {
                    .col {
                        flex-grow: 0;
                        &.--address {
                            min-width: 220px !important;
                        }
                        &.--assign {
                            min-width: 160px;
                            width: 160px;
                            display: flex;
                        }
                        &.--group {
                            min-width: 200px !important;
                        }
                    }
                    .rows {
                        overflow: visible;
                    }
                    &-list {
                        .col {
                            &.--address {
                                svg {
                                    position: absolute;
                                }
                                .end {
                                    margin-top: 3px;
                                }
                                .field-input {
                                    padding-left: 23px;
                                    background-image: none !important;
                                }
                            }
                        }
                        .rows {
                            input.field-input {
                                height: 24px;
                            }
                            &:hover {
                                background-color: $white-darkgrey;
                            }
                        }
                    }
                }
                .schedule-upgrade {
                    color: $black-2;
                    padding: 10px 16px;
                    @include betweenitems;
                    flex-wrap: wrap;
                    white-space: nowrap;
                    &__label {
                        @include flexcenter;
                        flex: 1;
                    }
                    .btn-modal {
                        margin-right: 8px;
                    }
                }
            }
            .tables {
                .col {
                    &.--name {
                        .col-label {
                            cursor: pointer;
                            color: $violet-name;
                            max-width: 100%;
                            width: fit-content;
                        }
                    }
                    &.col-date {
                        max-width: 240px;
                    }
                }
                &-list {
                    .dropdown-assign {
                        display: inline-block;
                        max-width: 100%;
                        margin: -2px 0 0 -5px;
                        &.active {
                            font-weight: 500;
                        }
                        .dropbtn {
                            justify-content: flex-start;
                            padding-left: 4px;
                            border: 0px;
                            width: 100%;
                            .txt-ellipsis {
                                width: auto;
                            }
                        }
                        .content-full {
                            min-width: 200px;
                        }
                    }
                    .tags-group {
                        @include flexwrap;
                        margin: -2px -4px;
                        min-height: 24px;
                        max-width: 200px;
                        &__wrap {
                            max-width: 100%;
                            @include flexwrap;
                        }
                        .tag-items {
                            display: inline-block;
                            background-color: $grey-greenish-light;
                            color: $grey-greenish;
                            font-weight: 600;
                            border-radius: 4px;
                            height: 24px;
                            line-height: 23px;
                            padding: 0px 8px;
                            @include text-ellipsis;
                            max-width: 100%;
                            margin: 1px;
                            &.is-available {
                                background-color: $red-light;
                                font-weight: 500;
                            }
                        }
                        .tag-list {
                            display: none;
                            width: 100%;
                            height: auto;
                            background: $white;
                            border: solid 1px $grey-light-2;
                            border-radius: 4px;
                            padding: 1px;
                            .scrolls:not(.v2-dropdown__menu) {
                                max-height: 100px;
                                @include flexwrap;
                                align-items: flex-start;
                            }
                            .tag-items {
                                cursor: default;
                                position: relative;
                                padding-right: 20px;
                                .blue-close {
                                    @include size(20px);
                                    @include centeritem;
                                    position: absolute;
                                    right: 0px;
                                    top: 2px;
                                    cursor: pointer;
                                    svg path {
                                        stroke: #7489ae;
                                        stroke-width: 1.8px;
                                    }
                                }
                            }
                            .add-tags {
                                border: 0;
                                display: inline-block;
                                min-width: 45px;
                                margin: 4px;
                                @include placeholder {
                                    color: $btn-hover-black;
                                    font-size: 15px;
                                }
                            }
                        }
                        .container-column {
                            max-width: 100%;
                            width: 100%;
                            position: absolute;
                            top: 0px;
                            left: -3px;
                            z-index: 1;
                            .v2-dropdown__menu {
                                position: static;
                                font-weight: 500;
                                overflow: auto;
                            }
                        }
                        .btn-add-tags {
                            color: $purple-default;
                            cursor: pointer;
                            font-weight: 500;
                            margin-left: 4px;
                        }
                        &.active {
                            .tag-list {
                                display: block;
                            }
                        }
                    }
                }
                &.--items {
                    .col {
                        &.--info {
                            word-break: break-word;
                            img {
                                display: block;
                                margin-bottom: 3px;
                            }
                        }
                    }
                }
                &.--service {
                    min-width: 1050px;
                    .tables-list {
                        overflow: visible;
                    }
                    .col {
                        &.--colorcode {
                            position: relative;
                            .color {
                                @include size(16px);
                                background-color: $red-bittersweet;
                                border-radius: 6px;
                                margin-right: 5px;
                                cursor: pointer;
                                border: solid 1px $white-cultured;
                            }
                            .code {
                                @include text-ellipsis;
                                max-width: calc(100% - 21px);
                            }
                            .dropdown-color {
                                position: absolute;
                                top: calc(100% - 10px);
                                z-index: 9;
                            }
                        }
                        &.--frequence {
                            .detail {
                                color: $grey-greenish;
                            }
                            .col__customer {
                                flex-direction: column;
                            }
                            .col-label {
                                min-height: auto;
                                max-width: 100%;
                            }
                        }
                    }
                }
                &.--email {
                    height: 100%;
                    .v2-btn-default {
                        svg {
                            @include size(22px);
                        }
                    }
                }
            }
        }
        .maintables-page {
            .container-delete-all {
                padding-left: 16px;
                .v2-btn-default:not(.--label) {
                    margin-right: 8px;
                }
            }
            .list-items {
                @include flexcenter;
            }
        }
        .page-account {
            .setting-wrap {
                height: 100%;
            }
            .boxs {
                &__title {
                    color: $black-2;
                    line-height: 24px;
                }
                &__contents {
                    margin: 15px 0px 10px;
                    .txt-ellipsis {
                        display: inline-block;
                        word-break: break-word;
                        white-space: normal;
                        line-height: 24px;
                    }
                    .info-card {
                        display: inline-block;
                        margin-left: 5px;
                    }
                }
                &__more:not(.loading) {
                    cursor: pointer;
                    color: $violet-name;
                    @include flexcenter;
                    .txt-ellipsis {
                        max-width: calc(100% - 30px);
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                }
                &.last-boxs {
                    border: 0;
                    padding: 10px 0px 20px;
                    .boxs__title,
                    .boxs__contents {
                        color: $grey-extrlight;
                    }
                    .boxs__contents {
                        margin: 5px 0px 10px;
                    }
                }
                .plans {
                    background-color: $orange-default;
                    color: $white;
                    font-size: 13px;
                    border-radius: 4px;
                    height: 24px;
                    line-height: 24px;
                    padding: 0px 6px 0px 1px;
                    cursor: pointer;
                    @include flexinline;
                    max-width: 100%;
                    svg {
                        margin-right: 2px;
                        flex: 0 0 auto;
                    }
                    .txt-ellipsis {
                        max-width: calc(100% - 24px);
                        white-space: nowrap;
                    }
                }
            }
        }
        .account-types {
            margin-bottom: 16px;
            padding: 18px 24px;
            border: 1px solid $orange-red;
            background-color: $orange-seashell;
            border-radius: 4px;
            &__title {
                @include flexleftcenter;
                .account-text {
                    margin-left: 10px;
                    width: calc(100% - 35px);
                    line-height: 24px;
                    font-weight: 600;
                    color: $orange-red;
                }
            }
            &__description {
                margin-top: 12px;
                line-height: 24px;
                color: $btn-hover-black;
            }
        }
        &:has(.react-datepicker-popper) {
            z-index: 10002;
        }
        &:not(.is-hide-panel) {
            .header__left:has(.btn-back) {
                .list-menu {
                    width: 190px;
                }
            }
        }
    }
    .account-expired__box {
        padding-top: 20px;
        color: $black-2;
        .label {
            font-size: 15px;
            margin-bottom: 5px;
        }
        .title {
            font-size: 18px;
            font-weight: 600;
            border-bottom: solid 1px $border-color-grey;
            padding-bottom: 3px;
            margin-bottom: 10px;
        }
    }
    .w-user-infomations-dropdown {
        margin-bottom: 15px;
        border: 1px solid $grey-soft;
        border-radius: 8px;
        .header-dropdown {
            @include betweenitems;
            padding: 14px 16px;
            border-radius: 8px;
            width: 100%;
            cursor: pointer;
            &.active {
                .btn-dropdown {
                    > svg {
                        @include transform(rotate(180deg));
                    }
                }
            }
            &__label {
                @include font-color(20px, 16px, $black-2);
                font-weight: 600;
                margin-bottom: 0;
                @include text-ellipsis;
                max-width: 75%;
            }
            .btn-dropdown {
                margin-left: 14px;
                cursor: pointer;
                > svg {
                    @include transitions(all 0.2s ease);
                }
            }
            .w-right {
                display: flex;
            }
        }
        .w-user-infomations {
            padding: 8px 16px 16px;
            &:not(.active) {
                display: none;
            }
        }
        .title {
            @include font-color(16px, 13px, $black-eerie);
            font-weight: 600;
            margin-bottom: 15px;
        }
        .w-check-options {
            margin-bottom: 25px;
            @include flexwrap;
            .title {
                width: 100%;
                margin-bottom: 5px;
            }
            .check-items {
                height: 32px;
                margin-right: 8px;
                margin-top: 10px;
                &:last-of-type {
                    margin-right: 0;
                }
                &__label {
                    padding-top: 0;
                }
            }
        }
        .w-list-custom:not(:last-child) {
            margin-bottom: 30px;
        }
        .list-custom {
            &__items {
                position: relative;
                padding-right: 32px;
                line-height: 24px;
                & + .list-custom__items {
                    margin-top: 24px;
                }
                .all {
                    display: inline-block;
                    margin-left: 5px;
                    color: $violet-name;
                    cursor: pointer;
                }
                .description {
                    font-size: 13px;
                }
                .switch {
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }
        .switch {
            .onoff {
                top: 0;
                &:after {
                    color: #808080;
                }
            }
            input.toggle-round:checked + label .onoff:after {
                color: $purple-default;
            }
        }
    }
    .set-document-list {
        height: 100%;
        overflow: auto;
        .check-items {
            @include flexcenter;
            width: 100%;
            border-bottom: 1px solid $grey-boder-line;
            padding: 0px 20px;
            height: 64px;
            &.--uncheck {
                input[type='checkbox']:checked + .item-checkbox label:after {
                    top: 7px;
                }
            }
            .name {
                margin-bottom: 0;
                @include font-color(18px, 13px, $black-5);
                cursor: pointer;
                &:hover {
                    color: $violet-name;
                }
            }
            > .item-checkbox {
                @include flexcenter;
                width: 100%;
                > label {
                    @include text-ellipsis;
                    width: auto;
                    line-height: 18px;
                    &::before {
                        top: 1px;
                    }
                }
            }
            input[type='checkbox']:checked {
                & + .item-checkbox {
                    label::after {
                        top: 5px;
                    }
                }
            }
        }
    }
    .setting-custom {
        .from-addr {
            color: $black-2;
            padding: 10px 16px;
            @include flexcenter;
            flex-wrap: wrap;
            z-index: 2;
            .v2-dropdown {
                width: auto;
                max-width: 50%;
                &__menu {
                    width: max-content;
                    min-width: 100%;
                    max-width: 400px;
                }
            }
            & + .tables.--email {
                border-top: 1px solid $border-color-grey;
            }
        }
        &:not(.has-details) {
            .system-email-template {
                display: none;
            }
        }
    }
    .setting-plans {
        .alert {
            margin-bottom: 0px;
        }
        .box-thankyou {
            @include size(100%);
            padding: 25px 50px;
            text-align: center;
            &__title {
                color: $purple-default;
                font-weight: 600;
            }
        }
    }
    .templates {
        .page-wrapper {
            padding-top: 18px;
        }
        .boxs {
            @include font-color(16px, 13px, $black-3);
            &__header {
                padding: 20px 25px 16px;
                border-bottom: 1px solid $border-color-grey;
                > .v2-btn-default {
                    margin-top: 13px;
                }
                > .has-form__label {
                    @include flexcenter;
                }
            }
            &__content {
                padding: 17px 25px 20px;
                &.has-form {
                    border-bottom: 1px solid $border-color-grey;
                    .v2-dropdown {
                        .dropbtn {
                            &.--grey-light {
                                color: $black-4;
                            }
                        }
                    }
                    .txt {
                        font-family: 'Inter Bold', sans-serif;
                    }
                    .rows {
                        &.flex-betweenitems {
                            .name {
                                @include flexcenter;
                            }
                        }
                    }
                }
                &:not(.has-form) {
                    padding-top: 20px;
                    .rows {
                        min-height: 40px;
                        @include betweenitems;
                        & + .rows {
                            margin-top: 0px;
                        }
                        .name {
                            @include flexcenter;
                        }
                    }
                    .v2-dropdown {
                        line-height: 20px;
                        min-width: 200px;
                    }
                }
                .rows {
                    &__view {
                        @include betweentop;
                        .v2-dropdown {
                            flex: 1;
                            margin-right: 8px;
                            width: calc(100% - 110px);
                        }
                    }
                    &__info {
                        @include font-color(16px, 12px, $grey-greenish);
                        margin-top: 4px;
                    }
                }
            }
            .tooltip {
                margin-left: 8px;
            }
            .desc {
                color: $black-4;
                word-break: break-word;
                line-height: 22px;
                white-space: pre-wrap;
            }
            .field-input,
            .field-textarea {
                color: $black-4;
                @include placeholder {
                    color: $grey-soft;
                }
            }
        }
    }
    .tiles {
        display: flex;
        .wrapper-box-edit {
            @include flexcolumns;
            width: 100%;
            .form-footer__action {
                max-width: 500px;
                justify-content: flex-end;
            }
        }
        .set-user-wrap {
            position: relative;
            max-width: 500px;
            width: 100%;
            margin: 0 auto;
        }
        .btn-drag {
            height: 24px;
            margin-right: 4px;
            cursor: grab;
        }
        .dots {
            margin: 0 6px;
            &::before {
                background-color: $background-circle;
            }
        }
        .w-user-infomations {
            border-radius: 8px;
            border: solid 1px $border-color-grey;
            margin-bottom: 15px;
            .content {
                @include betweenitems();
                flex-flow: row wrap;
                padding: 16px 0;
            }
            .rows {
                @include betweenitems;
                flex-wrap: nowrap;
                width: 100%;
                padding-left: 12px;
                padding-right: 24px;
                background-color: $white;
                margin: 0;
                &__item {
                    width: calc(50% - 30px);
                    .v2-btn-default,
                    .items {
                        @include font-color(16px, 13px, $black-4);
                    }
                }
                & + .rows {
                    margin-top: 16px;
                }
            }
        }
        .v2-dropup {
            &.disabled {
                .dropbtn {
                    &.items {
                        pointer-events: none;
                        border-color: $border-color-grey;
                        background-color: $bg-body;
                    }
                }
                .svg-selectbox {
                    opacity: 0.5;
                }
            }
            .v2-dropdown__menu {
                z-index: 10;
            }
        }
        .w-title-preview {
            @include flexend();
            position: relative;
            padding-left: 75px;
            height: 354px;
            .col-date {
                position: relative;
                width: 140px;
                height: 100%;
                &:not(:first-child) {
                    border-left: solid 1px $border-color-grey;
                }
                &__title {
                    @include centeritem;
                    font-size: 14px;
                    height: 24px;
                    border-bottom: 1px solid $border-color-grey;
                    .date {
                        margin-right: 4px;
                    }
                }
            }
            .row-time {
                border-top: solid 1px $border-color-grey;
                position: absolute;
                left: 0;
                width: 100%;
                &:nth-of-type(4) {
                    bottom: 220px;
                }
                &:nth-of-type(5) {
                    bottom: 110px;
                }
                .time {
                    position: absolute;
                    left: 0;
                    top: -8px;
                    background: $white;
                    padding: 0px 10px 0px 16px;
                    font-weight: 500;
                    font-size: 11px;
                    color: $grey-generic;
                }
            }
            .w-service-preview {
                position: relative;
                z-index: 9;
                width: 134px;
                margin-left: 2px;
                margin-top: 110px;
                background: #ddecbe;
                border: 1px solid #a9c850;
                border-radius: 2px;
                .events-job {
                    font-weight: 500;
                    min-height: inherit;
                    width: 100%;
                    line-height: 18px;
                    padding: 2px 0 2px 4px;
                    font-size: 11px;
                    > div {
                        margin: 2px 0;
                        word-break: break-word;
                        white-space: normal;
                    }
                }
            }
        }
    }
    .service-edit {
        .wrap-content-service {
            min-width: 920px;
            @include size(100%);
            @include flexcolumns;
            position: relative;
            .form-footer {
                position: absolute;
                width: calc(100% - 200px);
                right: 0px;
                bottom: 0px;
                &__action {
                    max-width: 700px;
                }
            }
            > .alert {
                max-width: calc(100% - 60px);
                margin: 0px 24px 20px;
            }
            &:has(.show-repeat-inv) {
                min-width: 948px;
            }
        }
        .content-services {
            display: flex;
            flex: 1 1 auto;
            overflow-y: auto;
            &__right {
                height: calc(100% - 80px);
                width: calc(100% - 240px);
                flex: 1;
                min-width: 650px;
                position: relative;
                > .tab-conts {
                    padding: 24px;
                    &:not(.form-material):not(.form-documents) {
                        height: auto;
                    }
                }
            }
            .sidebar-pages {
                width: 200px;
            }
            .alert {
                display: block;
            }
        }
        .container-setting-center {
            max-width: 700px;
            width: 100%;
            margin: 0 auto;
            border-radius: 3px;
            border: 1px solid $border-color-grey;
            .header-modal {
                height: 64px;
                padding: 0px 24px;
                @include betweenitems;
                flex: 0 0 auto;
            }
            .rows {
                &:last-child {
                    border-bottom: 0px;
                }
                &.--header-info {
                    padding: 0px 24px !important;
                    border-bottom: none;
                }
            }
            .line {
                margin: 16px 0;
            }
            .detail-toggle {
                height: auto;
            }
        }
        .form-tabpane-details {
            &.show-repeat-inv {
                .content-elm-edit.header-modal {
                    display: none;
                }
                .container-setting-center {
                    max-width: 720px;
                    .rows.--header-info {
                        padding: 0 !important;
                    }
                }
            }
            .form-edit-template {
                .items-service {
                    .col-price {
                        padding-right: 20px;
                    }
                }
                .items-payment-number {
                    max-width: 57%;
                }
            }
            &.form-add-estimate {
                .rows {
                    border-bottom: 1px solid $border-color-grey;
                }
            }
        }
        .form-material,
        .form-documents {
            .container-setting-center {
                height: 100%;
            }
            .details-job {
                overflow-y: auto;
                flex: 1;
            }
        }
        .form-documents {
            .modal {
                max-height: calc(100% - 100px);
                position: fixed;
                @media (max-width: 700px) {
                    &__container {
                        width: 95% !important;
                    }
                }
            }
            .bg-fixed {
                background-color: rgba(146, 155, 169, 0.8);
            }
            .modal-document {
                position: absolute;
                right: auto;
                left: 50%;
                top: 50%;
                @include transform(translate(-50%, -50%));
                width: 650px;
            }
        }
        .form-edit-material {
            .rows {
                .disable-anchor {
                    max-height: 240px;
                }
                &.--sm:last-child {
                    .v2-dropdown__menu {
                        max-width: 300px;
                    }
                }
                &:nth-child(n + 5) {
                    .v2-dropdown__menu {
                        bottom: calc(100% + 2px);
                        top: unset;
                    }
                }
            }
        }
        .form-invoice-repeat {
            height: auto !important;
            .rows .txt {
                width: 130px !important;
            }
            .footer-form {
                position: static;
            }
            .monthly-calendar {
                top: 50%;
                @include transform(translateY(-50%));
            }
            .dropdown-frequency {
                .v2-dropdown__menu {
                    bottom: calc(100% + 4px);
                    top: unset;
                }
            }
        }
        &.--service-plan {
            .form-footer {
                width: 100%;
                .form-footer__action {
                    max-width: 860px;
                }
            }
            .dashboard-job-detail {
                padding: 24px;
                .container-setting-center {
                    max-width: 860px;
                    .txt {
                        width: 240px;
                        padding-right: 5px;
                    }
                    .details {
                        flex: 1;
                    }
                }
            }
            .header-modal {
                border-bottom: 1px solid $border-color-grey;
            }
            .phone-field {
                .field-input {
                    width: 50px;
                }
                .dropbtn {
                    min-width: 80px;
                }
            }
            .delete-option {
                right: 0;
            }
            .dropdown-select-color {
                .search-input::placeholder {
                    color: $grey-soft;
                }
            }
            .rows__trigger {
                .dropdown-select-color {
                    width: 200px;
                }
            }
            .summary-items {
                &:not(:first-child) {
                    padding-top: 16px;
                    border-top: 1px solid $border-color-grey;
                }
                &:not(:last-child) {
                    margin-bottom: 16px;
                }
            }
        }
    }
    .invoices-details {
        .title-header {
            justify-content: flex-start;
            padding: 0 16px;
        }
        .boxs-wrapper {
            max-width: 496px;
            width: 100%;
            margin: 0 auto;
            padding: 8px 0 25px;
            .boxs {
                @include font-color(20px, 13px, $grey-dark);
                @include border($grey-soft, 4px, 'null');
                &__header {
                    @include betweenitems;
                    height: 64px;
                    padding: 0 24px;
                    .v2-btn-default {
                        margin-right: 8px;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                    .title {
                        font-size: 18px;
                        flex: 1;
                    }
                }
                &__header,
                &__content {
                    border-bottom: 1px solid $border-color-grey;
                    .rows + .rows {
                        margin-top: 20px;
                    }
                }
                &__content {
                    padding: 24px;
                }
                .rows {
                    &.--multi {
                        @include betweentop;
                    }
                    .txt {
                        width: 110px;
                        @include text-ellipsis;
                    }
                    .details {
                        width: calc(100% - 140px);
                        word-break: break-word;
                    }
                }
                .wrap-rows {
                    padding: 12px 24px 24px;
                    .rows {
                        @include betweenitems;
                        width: calc(100% - 120px);
                        margin-left: auto;
                        padding-right: 24px;
                        & + .rows {
                            margin-top: 8px;
                        }
                        .details {
                            display: flex;
                            justify-content: flex-end;
                            width: calc(100% - 110px);
                            p {
                                @include text-ellipsis;
                            }
                        }
                        .info-card {
                            .date {
                                width: auto;
                                padding: 0px 3px;
                            }
                        }
                    }
                }
                .tables {
                    .col {
                        &.--amount {
                            max-width: 120px;
                            font-weight: 600;
                        }
                    }
                }
            }
            @media only screen and (max-width: 768px) {
                max-width: 95%;
            }
        }
        .title {
            @include font-color(20px, 15px, $grey-extradark);
            @include text-ellipsis;
            font-weight: 600;
        }
    }
    // Style for page account invoice
    .wrapper-account {
        flex: 1;
        &.has-credit-card {
            .--billing-info {
                display: none;
            }
        }
        &:not(.has-credit-card) {
            .--credit-card {
                display: none;
            }
        }
        &__conts {
            &.--credit-card {
                > .v2-btn-default,
                .form-credit-card {
                    margin: 0 16px 16px;
                    line-height: 20px;
                }
            }
        }
        .tables {
            overflow-y: visible !important;
            .tables-list {
                overflow: visible;
                .rows {
                    align-items: center;
                }
            }
        }
        .col {
            &.has-card {
                .default-btn {
                    cursor: default;
                }
            }
            &.--menu {
                padding-right: 24px;
                .dropbtn svg circle {
                    fill: $background-circle;
                }
            }
            .check-radio {
                width: inherit;
                input[type='radio'] {
                    & + label {
                        height: 24px;
                        margin-top: 5px;
                        white-space: nowrap;
                        &::before {
                            top: 4px;
                            border-radius: 6px;
                        }
                    }
                    &:checked + label::after {
                        content: attr(active-card);
                        top: 8px;
                        text-indent: 23px;
                        line-height: 10px;
                    }
                }
            }
            &.col-invoice-id {
                min-width: 250px !important;
            }
        }
        .form-credit-card {
            width: 480px;
            border-radius: 3px;
            border: solid 1px $border-color-grey;
            &__header {
                padding: 0px 24px;
                height: 64px;
                @include flexcenter;
                .title {
                    @include text-ellipsis;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 32px;
                }
            }
            &__body {
                padding: 8px 24px 24px;
                border-top: 1px solid $border-color-grey;
                font-size: 13px;
            }
            .row {
                & + .row {
                    margin-top: 16px;
                }
                .col {
                    & + .col {
                        margin-left: 10px;
                    }
                    &.--md {
                        width: 115px;
                    }
                }
            }
        }
        > .title-header {
            border-bottom: 1px solid $border-color-grey;
        }
        .btn-load {
            min-width: 150px;
            justify-content: center;
        }
        &:has(.--billing-info) > * {
            min-width: 900px;
            .--menu {
                .v2-dropdown__menu {
                    min-width: 165px;
                    width: max-content !important;
                }
            }
        }
    }
    // Pages have edit and save state
    .wrapper-box-edit {
        .box-tips {
            margin-bottom: 0px;
        }
        .form-editing {
            .page-wrapper {
                .logo {
                    min-height: 150px;
                    max-height: 180px;
                    @include flexcenter;
                }
                .is-loading-box {
                    @include size(150px);
                    @include centeritem;
                }
            }
            .rows {
                &.--edit-logo {
                    padding-bottom: 20px;
                }
                &.--operating {
                    @include flexcenter;
                    .select-time {
                        width: 110px;
                        margin: 0px 8px;
                    }
                }
                &__label {
                    color: $grey-very-dark;
                }
                &__info {
                    .dropbtn,
                    .field-input {
                        color: $black;
                    }
                    .field-input {
                        @include placeholder {
                            font-size: 13px;
                        }
                    }
                    .wrap-existing-tag {
                        .v2-btn-default {
                            margin-right: 5px;
                            margin-bottom: 1px;
                        }
                        .tag-label {
                            line-height: 20px;
                            margin: 1px 4px 1px 0;
                            min-width: unset;
                        }
                    }
                    .v2-dropdown,
                    &.v2-dropdown {
                        .dropbtn {
                            &:hover {
                                border-right-color: $black-darker;
                            }
                        }
                        &.active {
                            .dropbtn {
                                &:hover {
                                    border-right-color: $grey-soft;
                                }
                            }
                        }
                    }
                }
            }
            .holiday-items {
                .check-items {
                    max-width: calc(100% - 175px);
                    .txt-ellipsis {
                        display: block;
                        line-height: 16px;
                    }
                }
            }
            .select-status {
                .dots {
                    margin-right: 4px;
                    &:before {
                        @include size(12px);
                        border: 1px solid #ffffff;
                        box-shadow: 0 0 1px 1px $black-darker2;
                        background-color: var(--branch-color);
                    }
                }
                .v2-dropdown__menu {
                    min-width: fit-content !important;
                    .items {
                        margin: 2px;
                        &:last-child {
                            padding: 4px 10px !important;
                        }
                    }
                }
            }
        }
        .form-default {
            .logo {
                width: calc(100% - 80px);
            }
            .page-wrapper {
                .rows {
                    & + .rows {
                        margin-top: 20px;
                    }
                    &__label {
                        line-height: 20px;
                    }
                    &__info {
                        .wrap-existing-tag {
                            margin-top: -4px;
                        }
                    }
                }
            }
        }
        .upload-img {
            > input[type='file'] {
                display: none;
            }
        }
        .form-footer {
            z-index: 2;
        }
    }
    // User setting
    .contents-user {
        &__details {
            display: flex;
            flex: 1;
            .tag-label {
                font-weight: 400;
                cursor: default;
            }
        }
        .box-tips:not(.hide) {
            & + .contents-user__details {
                box-shadow: inset 0px 1px 0px $border-color-grey;
            }
        }
        .set-user-left {
            letter-spacing: 0.01em;
            width: 330px;
            min-width: 330px;
            .list-details__items {
                padding: 16px 8px 16px 12px;
                .username-tags:empty {
                    display: none;
                }
            }
        }
        .set-user-right {
            @include flexcolumns;
            overflow-y: auto;
            width: calc(100% - 330px);
            min-width: 600px;
            position: relative;
            &.has-session-history {
                overflow-y: hidden;
                .wrapper-box-edit__content {
                    display: none;
                }
            }
            &:not(.has-session-history) {
                .wrap-session-history {
                    display: none;
                }
            }
            .page-wrapper {
                width: 550px;
                max-width: 550px;
            }
            .form-footer__action {
                max-width: 530px;
            }
        }
        &:has(.set-user-left) {
            .set-user-right {
                width: calc(100% - 310px);
            }
        }
        .tag-label:not(.--technician):not(.--admin):not(.--super-admin):not(.--crew) {
            background: #f0f0f0;
        }
        .list-details {
            .username-header {
                line-height: 20px;
                @include betweentop;
                .avt-img {
                    @include size(40px);
                }
                &__info {
                    width: calc(100% - 185px);
                    margin: 0px 8px;
                    .date {
                        font-size: 11px;
                    }
                    .name {
                        @include text-ellipsis;
                    }
                }
                .tag-label {
                    height: 20px;
                    font-weight: 500;
                    &:not(.--crew) {
                        color: $white;
                    }
                }
            }
            .username-tags {
                margin-top: 6px;
                margin-left: 46px;
                .tag-label {
                    color: $black-eerie;
                    margin: 2px;
                    display: inline-block;
                    line-height: 20px;
                    max-width: 100%;
                    min-width: auto;
                    @include text-ellipsis;
                }
            }
        }
        .page-wrapper {
            .select-avatar {
                position: relative;
                flex: 1;
                &__btn {
                    @include flexinline;
                    .avatar-box {
                        margin-right: 8px;
                    }
                }
                &:not(.is-open) {
                    .avatar-user-list {
                        display: none;
                    }
                }
                &.is-open {
                    .arrow {
                        @include transform(rotateZ(180deg));
                    }
                }
                .arrow svg {
                    @include transform(scale(1.1));
                }
            }
            .avatar-box {
                position: relative;
                @include centeritem;
                @include size(46px);
                cursor: pointer;
                padding: 1px;
                border-radius: 50%;
                border: solid 1px $transparent;
                flex: 0 0 auto;
                &:hover,
                &.active {
                    border: solid 1px $purple-default;
                }
                &::after {
                    content: '';
                    width: calc(100% + 4px);
                    height: calc(100% + 4px);
                    border: solid 3px $purple-default;
                    position: absolute;
                    border-radius: 50%;
                    display: none;
                }
            }
            .avatar-user-list {
                position: absolute;
                top: calc(100% + 5px);
                left: 0px;
                background: $white;
                box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.05), 0px 2px 3px rgba(0, 0, 0, 0.03),
                    0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.08);
                border-radius: 4px;
                &__details {
                    @include grid('', auto auto auto auto auto, 8px);
                    padding: 24px;
                }
                .avatar-box {
                    &:active,
                    &.active {
                        &::after {
                            display: block;
                        }
                    }
                }
            }
            .avatar-img {
                border-radius: 50%;
                object-fit: cover;
                background-color: $grey-light;
            }
            .list-items {
                & + .list-items {
                    margin-top: 8px;
                }
            }
            .rows {
                &.--lg + .--lg {
                    margin-top: 24px;
                }
            }
            .deleted-user {
                width: 100%;
                border-color: $red-default;
            }
        }
        .wrapbox-user {
            &__frame {
                border: 1px solid $border-color-grey;
                border-radius: 8px;
                padding: 24px;
                margin-top: 16px;
                &:not(.has-collapse) {
                    .permissions-content {
                        display: none;
                    }
                    .icon-collapse {
                        @include transform(rotateZ(180deg));
                    }
                }
            }
            .permissions-header {
                @include betweenitems;
                cursor: pointer;
            }
            .permissions-content {
                margin-top: 12px;
            }
            .rows {
                .rows__label {
                    width: 180px;
                    padding-right: 4px;
                }
                .rows__info {
                    width: calc(100% - 180px);
                }
            }
        }
        .wrap-sessions {
            line-height: 20px;
            color: $black-eerie;
            &.is-empty {
                padding: 20px 24px;
                @include betweenitems;
                flex-wrap: wrap;
                gap: 8px;
                .dots {
                    color: $grey-generic;
                    &::before {
                        background-color: #b2b2b2;
                    }
                }
                .wrap-sessions__header {
                    @include flexcenter;
                }
            }
            &:not(.is-empty) {
                padding: 0px;
                .dots {
                    display: none;
                }
                .wrap-sessions__header {
                    padding: 24px 24px 8px;
                }
                .wrap-sessions__list {
                    padding: 0px 24px;
                }
                .wrap-sessions__link {
                    border-top: 1px solid $border-color-grey;
                    padding: 8px 0px;
                    @include centeritem;
                }
            }
            .device-items {
                @include flextop;
                padding: 16px 0px;
                & + .device-items {
                    border-top: 1px solid $border-color-grey;
                }
                &__icon {
                    @include size(40px);
                    background: $white-cultured;
                    border-radius: 50%;
                }
                &__info {
                    margin: 0px 8px;
                    .time {
                        color: $granite-gray;
                        margin-left: 4px;
                    }
                    .last-login {
                        margin-top: 2px;
                    }
                }
                .v2-btn-default {
                    margin-top: 4px;
                }
            }
        }
        .wrap-session-history {
            position: absolute;
            overflow-y: auto;
            height: 100%;
            background: $white;
            &__header {
                padding: 16px 24px 16px 18px;
                z-index: 2;
                box-shadow: 0 1px 0 $border-color-grey;
                .field-date__select {
                    color: $grey-dark;
                }
                &:has(.react-datepicker-popper) {
                    z-index: 103;
                }
                .react-datepicker-popper {
                    @media (max-height: 740px) {
                        .wrapper-select-range {
                            height: 335px;
                            .filter-range {
                                max-height: unset;
                                min-height: 335px;
                            }
                        }
                        .react-datepicker__day-name {
                            width: 38px;
                        }
                        .react-datepicker__day {
                            @include size(38px);
                        }
                        .datepicker-header-custom,
                        .filter-range__date {
                            height: 42px;
                        }
                    }
                }
            }
            .tables {
                flex: 1;
                overflow-y: auto;
                .rows {
                    &.--header {
                        align-items: center;
                    }
                    .v2-btn-default {
                        height: 26px !important;
                        width: fit-content;
                    }
                }
                .col {
                    &.--device {
                        flex-basis: 150px;
                        min-width: 260px;
                    }
                    &.--signout {
                        min-width: 115px;
                    }
                }
                .flex-monitor {
                    margin-left: -3px;
                    margin-top: 4px;
                    svg {
                        margin-top: -3px;
                    }
                }
                .word-break {
                    margin-top: 5px;
                }
                .preloader {
                    position: relative !important;
                }
                & > * {
                    min-width: 900px;
                }
            }
            .wrap-session-history__header {
                flex: 0 0 auto;
                position: sticky;
                left: 0;
                right: 0;
            }
        }
        .form-authentication {
            .page-wrapper {
                width: 600px;
                max-width: 600px;
            }
            .wrapbox-user__frame:not(.wrap-sessions) {
                padding: 24px 0 14px;
                &.--edit {
                    padding-bottom: 0;
                }
            }
            .row {
                padding: 0 24px;
                &.--desc {
                    line-height: 20px;
                }
            }
            .txt-label {
                line-height: 32px;
            }
            .rows {
                overflow: unset;
                margin-top: 0 !important;
                background-color: $white !important;
                &.--header {
                    background-color: $white-anti-flash !important;
                }
                .col {
                    &:first-child {
                        padding-left: 24px;
                    }
                    &:last-child {
                        padding-right: 10px;
                    }
                }
            }
            .tables {
                border-top: 1px solid $grey-platinum;
                overflow-y: unset;
            }
            .v2-dropdown__menu {
                z-index: 3;
            }
        }
    }
    .wrap-list-items {
        @include flexcolumns;
        height: 100%;
        border-right: 1px solid $border-color-grey;
        .header-title {
            flex: 0 0 auto;
            min-height: 56px;
            height: auto;
            padding: 10px 12px !important;
            flex-wrap: wrap;
            gap: 5px;
        }
        .header-title,
        .list-details__items {
            box-shadow: inset 0px -1px 0px $border-color-grey;
        }
        .list-details {
            flex: 1;
            &__items {
                padding: 16px 18px 16px 16px;
                cursor: pointer;
                &:hover {
                    background: #fff7e8;
                }
                &:active,
                &.active {
                    background: #fff7e8;
                    box-shadow: inset -3px 0px 0px $yellow-default, inset 0 -1px 0 $border-color-grey;
                }
            }
        }
    }
    // Manage template page
    .wrapper-template {
        &__main {
            @include flextop;
            height: 100%;
            line-height: 18px;
            overflow-x: auto;
        }
        .sidebar-pages {
            overflow-y: auto;
            ul > .border-line:last-child {
                display: none;
            }
        }
        .wrap-list-items {
            width: 310px;
            &__search {
                padding: 10px 12px 10px 16px;
                box-shadow: 0px 1px 0px $border-color-grey;
            }
        }
        .list-details {
            overflow-y: auto;
            margin-top: 1px;
            &:not(.is-active) {
                display: none;
            }
            &__items {
                @include flextop;
                padding-right: 8px;
                .v2-btn-default {
                    margin: 4px 0 0 8px;
                }
            }
        }
        .template-name {
            .status-btn {
                margin-top: 2px;
            }
        }
        .wrap-note-template {
            height: 100%;
            min-width: 450px;
            &:has(.template-content__body) {
                min-width: 750px;
            }
            &:has(.wrap-estimate) {
                min-width: 700px;
            }
            &__content {
                flex: 1;
                height: calc(100% - 50px);
                &:not(.is-show) {
                    visibility: hidden;
                }
            }
            .alert {
                margin-bottom: 0px;
            }
            &.payment-term {
                min-width: 650px;
                .template-header__name {
                    @include flexcenter;
                    gap: 8px;
                    .col {
                        @include flexcolumns;
                        gap: 2px;
                        &.col-fee {
                            width: 150px;
                        }
                        &.col-name {
                            flex: 1;
                        }
                        .has-many-field__items {
                            margin-bottom: 0;
                            .v2-dropdown,
                            .field-input {
                                width: 50%;
                            }
                        }
                    }
                }
            }
        }
        .template-header {
            padding: 18px 24px 24px;
            border-bottom: 1px solid $border-color-grey;
            .title {
                margin-bottom: 2px;
            }
        }
        .template-content {
            overflow-y: auto;
            .wrap-content {
                flex: 1;
                overflow: hidden auto;
                > .wrap-editor,
                .todo-items {
                    box-shadow: none;
                    border-bottom: solid 1px $border-color-grey;
                }
                .todo-items__action {
                    border-top: none;
                }
            }
            &__footer {
                padding: 24px;
            }
            &__body {
                @include flextop;
                justify-content: center;
                gap: 16px;
                padding: 20px 24px;
                flex: 1;
                overflow-y: auto;
                & > * {
                    width: 50%;
                    max-width: 350px;
                }
                .container-content-email {
                    padding: 0;
                }
                .wrap-content-email {
                    border: 1px solid $grey-verylight;
                    width: 100%;
                    min-height: 52dvh;
                    .sms-message__info {
                        color: $black-eerie-dark;
                        @include flexcolumns;
                        gap: 5px;
                        .message-desc {
                            min-height: 30px;
                            &:empty {
                                display: none;
                            }
                        }
                        .box-attach {
                            @include flexcolumns;
                            align-items: flex-end;
                            .img-attach {
                                max-width: 165px;
                                img {
                                    margin: 0;
                                    border-radius: 12px 4px 12px 12px;
                                }
                                & + .pdf-attach {
                                    margin-top: 5px;
                                }
                            }
                            .pdf-attach {
                                width: 165px;
                                & + .img-attach {
                                    margin-top: 5px;
                                }
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                            &.has-manyimage {
                                .img-attach {
                                    &:first-child img {
                                        border-radius: 12px 4px 0px 0px;
                                    }

                                    &:last-child img {
                                        border-radius: 0px 0px 12px 12px !important;
                                    }
                                }
                            }
                        }
                        .video-attach {
                            max-width: 65%;
                            video {
                                border-radius: 12px;
                            }
                        }
                        .attachment-audio {
                            audio {
                                max-width: 100%;
                            }
                        }
                    }
                }
                .wrap-content {
                    overflow: unset;
                }
                .wrapbox-editor {
                    border: 1px solid $grey-verylight;
                    .DraftEditor-editorContainer {
                        max-height: 350px;
                    }
                    &__controls {
                        box-shadow: inset 0 1px 0 $grey-verylight;
                        .v2-dropdown__menu {
                            max-height: 250px;
                        }
                    }
                }
                .txt-title {
                    line-height: 24px;
                }
                .dots::before {
                    margin-top: 4px;
                }
                .v2-dropdown__menu > div:has(> em-emoji-picker) {
                    width: 316px;
                }
            }
            .wrap-estimate {
                overflow-y: auto;
                .tab-conts {
                    padding: 16px;
                }
                .container-setting-center {
                    max-width: 650px;
                    margin: 0 auto;
                    border-radius: 4px;
                    border: 1px solid $border-color-grey;
                }
                .note-details {
                    border-left: 0;
                    border-right: 0;
                    border-radius: 0 0 4px 4px;
                }
            }
        }
        .wrapbox-editor {
            @include flexcolumns;
            border: none;
            height: 100%;
            &__form {
                flex: 1;
                overflow: hidden;
                &-attachment {
                    padding: 12px 12px 0;
                    .file-attachment {
                        height: auto;
                        display: flex;
                        padding-bottom: 5px;
                        overflow-y: hidden;
                        max-width: 100%;
                    }
                    &:has(.file-attachment-conts:empty) {
                        display: none;
                    }
                }
            }
            .public-DraftEditor-content {
                height: 100%;
                padding: 16px 24px;
            }
            .DraftEditor-root,
            .DraftEditor-editorContainer {
                height: 100%;
            }
            .editor-controls {
                gap: 4px;
            }
        }
    }
    .contents-pages {
        width: calc(100% - 245px);
    }
    .sidebar-pages {
        width: 245px;
    }
    &:not(:has(.is-disable-calls)) {
        &:has(.call-bar:nth-of-type(1)) {
            .form-documents {
                .modal {
                    max-height: calc(100% - 130px);
                    top: 106px !important;
                }
            }
        }
        @media (min-height: 800px) {
            &:has(.call-bar + .call-bar) {
                .form-documents {
                    .modal {
                        max-height: calc(100% - 200px);
                        top: 185px !important;
                    }
                }
            }
        }
    }
    // Global Override
    .global-override {
        .sidebar-pages .items {
            &:hover,
            &.active {
                color: $black-eerie-dark;
            }
        }
        .override-container {
            width: 100%;
            height: calc(100% - 162px);
            flex: 1;
            .tables {
                height: calc(100% - 49px);
                .tables-list {
                    .rows {
                        align-items: center;
                        background-color: $white;
                    }
                    .col {
                        .tabs {
                            width: 150px;
                            .btn-item {
                                &__input {
                                    border: 0;
                                    padding-left: 5px;
                                    flex: 1;
                                    width: 100%;
                                }
                                .tab-items {
                                    padding: 0;
                                    width: 30px;
                                }
                                &.field-error {
                                    .btn-item__input {
                                        background-color: $transparent;
                                        color: $red-default;
                                    }
                                }
                            }
                        }
                    }
                }
                .rows {
                    &.--header {
                        background-color: $white-ghost !important;
                        border-top: 1px solid $border-color-grey;
                    }
                    .col:last-child {
                        max-width: 180px;
                    }
                    @media (max-width: 1180px) {
                        .col-xl {
                            max-width: unset !important;
                        }
                    }
                }
            }
            &__footer {
                padding: 8px 10px;
                border-top: 1px solid $border-color-grey;
                .tooltiptext.top {
                    left: unset;
                    right: 0;
                    @include transform(none !important);
                }
            }
        }
    }
    .page-plans {
        min-width: max-content;
    }
    // Reponsive
    @media only screen and (max-device-width: 1366px) {
        .contents-pages {
            &:not(.setting-schedules):not(.contents-user) {
                .tables {
                    &.has-checkbox {
                        .col:nth-child(2) {
                            max-width: none !important;
                        }
                    }
                    .col {
                        &.--name .col-label {
                            width: auto;
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 1220px) {
        .set-user-right {
            &.has-session-history {
                min-width: 800px;
            }
        }
    }
    @media only screen and (max-width: 820px) {
        .container-setting {
            .page-wrapper {
                margin: 20px auto 0 !important;
                padding: 24px 10px !important;
            }
            .form-footer {
                padding: 0 !important;
            }
        }
    }
}
// Custom form card Stripe
.StripeElement {
    padding: 0px 8px !important;
}
// Modal-re-assign
.modal-re-assign {
    .modal__container {
        width: 580px;
        .body-modal {
            padding: 32px 16px;
            font-size: 14px;
            line-height: 20px;
        }
        .desc {
            word-break: break-word;
            color: $grey-dark;
            &.--space {
                margin-top: 16px;
            }
        }
        .move-action {
            @include betweenitems;
            margin-top: 3px;
            .v2-dropdown {
                width: calc(50% - 20px);
                &__menu {
                    max-height: 250px;
                    @media (max-height: 800px) {
                        bottom: calc(100% + 4px);
                        top: unset;
                    }
                    @media (max-height: 650px) {
                        max-height: 180px;
                    }
                }
            }
            &__arrow {
                @include size(32px);
                @include centeritem;
                margin: 0px 4px;
            }
        }
    }
}
#uvTab {
    display: none;
}
// Modal authenticator app
.modal-authenticator {
    .step-list {
        list-style: decimal;
        color: $black;
        padding-left: 14px;
        .steps {
            & + .steps {
                margin-top: 16px;
            }
        }
    }
    .backup-code {
        width: 325px;
        background-color: $white-anti-flash;
        padding: 20px;
        column-count: 2;
        column-gap: 55px;
        border-radius: 4px;
        line-height: 24px;
    }
}
// Select holiday
.modal-select-holidays {
    .modal__container {
        max-width: 400px;
    }
    .body-modal {
        border-top: 0;
        padding: 0;
        height: calc(100% - 64px);
        .search-input {
            border-bottom: 1px solid $border-color-grey;
            .field-input {
                padding: 8px 10px 8px 36px;
                height: 40px;
            }
            .svg-search-absolute {
                left: 5px;
            }
        }
        .list-holidays {
            overflow-y: auto;
            .check-items {
                padding: 10px;
                .txt-ellipsis {
                    display: block;
                    line-height: 16px;
                }
            }
        }
    }
}
