@import 'src/assets/css/common/variables';
@import 'src/assets/css/common/mixins';

.wrap-collapse {
    &:not(.is-open) {
        .arrow {
            @include transform(rotateZ(180deg));
        }
        .wrap-collapse__details {
            display: none;
        }
    }
}
.wrap-note {
    border-bottom: 1px solid $border-color-grey;
    &.is-location {
        &:hover {
            background: $grey-verylight-brown;
        }
    }
    &.is-topnote {
        background: rgba(255, 187, 0, 0.1);
        &:hover {
            background: rgba(255, 187, 0, 0.2);
        }
    }
    &.is-jobnote {
        background: #f2f5f8;
        &:hover {
            background: #e8eef4;
        }
    }
    .note-header {
        cursor: pointer;
        min-height: 20px;
        padding: 8px 16px;
    }
    .note-details {
        padding: 0 16px 8px;
        word-break: break-word;
    }
}
